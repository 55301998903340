import * as React from 'react';
import { useState, useEffect, Component } from 'react';
import { resolveBrowserLocale, useTranslate, withTranslate } from 'react-admin'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PostAdd from '@material-ui/icons/PostAdd';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { showNotification } from 'react-admin';
import { push as pushAction } from 'react-router-redux'
import { connect } from 'react-redux';

import dataProvider from '../dataProvider'


class Page extends Component {

    constructor(props) {
        super(props)

        this.state = {
            arr: [
                "Denominationsociales",
                "Emails",
                "Phones",
                "Websites",
                "Socials"
            ],
            resource: "",
            source: [],
            distinct: "false"
        }
    }

    componentDidMount() {

    }

    callGetSources() {
        dataProvider.customs.getSources(this.state.resource.toLowerCase()).then(res => {
            this.setState({
                sources: res.ret.map(elem => elem.value)
            })
        })
    }

    callCount() {
        dataProvider.customs.count(this.state.resource.toLowerCase(), this.state.source, this.state.distinct).then(res => {
            this.setState({
                result: res.ret.total
            })
        })
    }

    render() {

        return (
            <div>
                <div>
                    <FormControl variant="outlined" style={{ width: "200px", marginTop: "10px" }}>
                        <InputLabel>Resource</InputLabel>
                        <Select
                            value={this.state.resource}
                            onChange={(e) => this.setState({ resource: e.target.value, sources: null }, () => this.callGetSources())}
                            label="Resource"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                this.state.arr.map(elem => (<MenuItem value={elem}>{elem}</MenuItem>))
                            }
                        </Select>
                    </FormControl>
                </div>
                {
                    this.state.sources && (
                        <div>
                            <div>
                                <FormControl variant="outlined" style={{ width: "200px", marginTop: "10px" }}>
                                    <InputLabel>Source</InputLabel>
                                    <Select
                                        value={this.state.source}
                                        onChange={(e) => this.setState({ source: e.target.value })}
                                        label="Source"
                                        multiple
                                    >
                                        {
                                            this.state.sources.map(elem => (<MenuItem value={elem}>{elem}</MenuItem>))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <FormControl variant="outlined" style={{ width: "200px", marginTop: "10px" }}>
                                    <InputLabel>Distinct</InputLabel>
                                    <Select
                                        value={this.state.distinct}
                                        onChange={(e) => this.setState({ distinct: e.target.value })}
                                        label="Distinct"
                                    >
                                        <MenuItem value={"false"}>False</MenuItem>
                                        <MenuItem value={"true"}>True</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <Button
                                    style={{ width: "200px", marginTop: "10px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.callCount()}
                                >
                                    Search
                                </Button>
                            </div>
                        </div>
                    )
                }
                {
                    this.state.result && (
                        <div
                            style={{ width: "180px", marginTop: "40px", padding: "10px", textAlign: "center", fontWeight: "bold", border: "1px solid black" }}
                        >
                            {this.state.result}
                        </div>
                    )
                }
            </div>
        )
    }
}

export default connect(null, {
    showNotification: showNotification,
    push: pushAction,
})(withTranslate(Page));
