import * as React from 'react';
import { useState, useEffect, Component } from 'react';
import { resolveBrowserLocale, useTranslate, withTranslate } from 'react-admin'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PostAdd from '@material-ui/icons/PostAdd';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { showNotification } from 'react-admin';
import { push as pushAction } from 'react-router-redux'
import { connect } from 'react-redux';

import dataProvider from '../dataProvider'


class Page extends Component {

    constructor(props) {
        super(props)

        this.state = {
            table1: null,
            table2: null,
            table3: null,
            table4: null,
            section: null,
            maj: null,
            min: null,
            etatAdministratif: null
        }
        this.etatAdministratif = [
            [null, "None"],
            ["A", "Actif"],
            ["C", "Cessé"],
        ]
    }

    componentDidMount() {
        this.callSection()
    }

    callSection() {
        dataProvider.customs.ficheActivites.getSections(this.state.etatAdministratif).then(res => {
            this.setState({ table1: Object.keys(res.data).map(elem => ({ name: elem, label: `${render_activitePrincipaleUniteLegale[elem]} (${res.data[elem]})` })) })
        })
    }

    callMaj() {
        this.setState({ table2: null, table3: null, table4: null }, () => {
            dataProvider.customs.ficheActivites.getMaj(this.state.section, this.state.etatAdministratif).then(res => {
                this.setState({ table2: Object.keys(res.data).map(elem => ({ name: elem, label: `${elem}: ${render_activitePrincipaleUniteLegale[elem]} (${res.data[elem]})` })) })
            })
        })
    }

    callMin() {
        this.setState({ table3: null, table4: null }, () => {
            dataProvider.customs.ficheActivites.getMin(this.state.maj, this.state.etatAdministratif).then(res => {
                this.setState({ table3: Object.keys(res.data).map(elem => ({ name: elem, label: `${elem}: ${render_activitePrincipaleUniteLegale[elem]} (${res.data[elem]})` })) })
            })
        })
    }

    call() {
        this.setState({ table4: null }, () => {
            dataProvider.customs.ficheActivites.get(this.state.min, this.state.etatAdministratif).then(res => {
                this.setState({ table4: Object.keys(res.data).map(elem => ({ name: elem, label: `${elem}: ${render_activitePrincipaleUniteLegale[elem]} (${res.data[elem]})` })) })
            })
        })
    }

    callAll() {
        this.callSection()
        if (this.state.section)
            this.callMaj()
        if (this.state.maj)
            this.callMin()
        if (this.state.min)
            this.call()
    }

    render() {

        const style = {
            border: "1px solid black",
            height: "25px"
        }

        return (
            <div>
                <div>
                    <FormControl variant="filled" style={{ margin: "20px", minWidth: "200px" }}>
                        <InputLabel>EtatAdministratif</InputLabel>
                        <Select
                            value={this.state.etatAdministratif}
                            onChange={(event) => {
                                this.setState({ etatAdministratif: event.target.value }, () => this.callAll())
                            }}
                        >
                            {
                                this.etatAdministratif.map((elem, index) => <MenuItem value={elem[0]}>{elem[1]}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </div>
                {
                    this.state.table1 && (
                        <table style={{ border: "1px solid black", backgroundColor: "#eeeeee" }}>
                            <td>
                                {
                                    this.state.table1.map(elem => <tr style={{ ...style, backgroundColor: this.state.section === elem.name ? "white" : undefined }} onClick={() => this.setState({ section: elem.name }, () => this.callMaj(elem.name))}>{elem.label}</tr>)
                                }
                            </td>
                        </table>
                    )
                }
                {
                    this.state.table2 && (
                        <table style={{ border: "1px solid black", backgroundColor: "#dddddd" }}>
                            <td>
                                {
                                    this.state.table2.map(elem => <tr style={{ ...style, backgroundColor: this.state.maj === elem.name ? "white" : undefined }} onClick={() => this.setState({ maj: elem.name }, () => this.callMin(elem.name))}>{elem.label}</tr>)
                                }
                            </td>
                        </table>
                    )
                }
                {
                    this.state.table3 && (
                        <table style={{ border: "1px solid black", backgroundColor: "#cccccc" }}>
                            <td>
                                {
                                    this.state.table3.map(elem => <tr style={{ ...style, backgroundColor: this.state.min === elem.name ? "white" : undefined }} onClick={() => this.setState({ min: elem.name }, () => this.call(elem.name))}>{elem.label}</tr>)
                                }
                            </td>
                        </table>
                    )
                }
                {
                    this.state.table4 && (
                        <table style={{ border: "1px solid black", backgroundColor: "#bbbbbb" }}>
                            <td>
                                {
                                    this.state.table4.map(elem => <tr style={style}>{elem.label}</tr>)
                                }
                            </td>
                        </table>
                    )
                }
            </div>
        )
    }
}

export default connect(null, {
    showNotification: showNotification,
    push: pushAction,
})(withTranslate(Page));

const render_activitePrincipaleUniteLegale = {
    'SECTION A': "AGRICULTURE, SYLVICULTURE ET PÊCHE",
    '01': "Culture et production animale, chasse et services annexes",
    '01.1': "Cultures non permanentes",
    '01.11': "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses",
    '01.11Z': "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses",
    '01.12': "Culture du riz",
    '01.12Z': "Culture du riz",
    '01.13': "Culture de légumes, de melons, de racines et de tubercules",
    '01.13Z': "Culture de légumes, de melons, de racines et de tubercules",
    '01.14': "Culture de la canne à sucre",
    '01.14Z': "Culture de la canne à sucre",
    '01.15': "Culture du tabac",
    '01.15Z': "Culture du tabac",
    '01.16': "Culture de plantes à fibres",
    '01.16Z': "Culture de plantes à fibres",
    '01.19': "Autres cultures non permanentes",
    '01.19Z': "Autres cultures non permanentes",
    '01.2': "Cultures permanentes",
    '01.21': "Culture de la vigne",
    '01.21Z': "Culture de la vigne",
    '01.22': "Culture de fruits tropicaux et subtropicaux",
    '01.22Z': "Culture de fruits tropicaux et subtropicaux",
    '01.23': "Culture d'agrumes",
    '01.23Z': "Culture d'agrumes",
    '01.24': "Culture de fruits à pépins et à noyau",
    '01.24Z': "Culture de fruits à pépins et à noyau",
    '01.25': "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque",
    '01.25Z': "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque",
    '01.26': "Culture de fruits oléagineux",
    '01.26Z': "Culture de fruits oléagineux",
    '01.27': "Culture de plantes à boissons",
    '01.27Z': "Culture de plantes à boissons",
    '01.28': "Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques",
    '01.28Z': "Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques",
    '01.29': "Autres cultures permanentes",
    '01.29Z': "Autres cultures permanentes",
    '01.3': "Reproduction de plantes",
    '01.30': "Reproduction de plantes",
    '01.30Z': "Reproduction de plantes",
    '01.4': "Production animale",
    '01.41': "Élevage de vaches laitières",
    '01.41Z': "Élevage de vaches laitières",
    '01.42': "Élevage d'autres bovins et de buffles",
    '01.42Z': "Élevage d'autres bovins et de buffles",
    '01.43': "Élevage de chevaux et d'autres équidés",
    '01.43Z': "Élevage de chevaux et d'autres équidés",
    '01.44': "Élevage de chameaux et d'autres camélidés",
    '01.44Z': "Élevage de chameaux et d'autres camélidés",
    '01.45': "Élevage d'ovins et de caprins",
    '01.45Z': "Élevage d'ovins et de caprins",
    '01.46': "Élevage de porcins",
    '01.46Z': "Élevage de porcins",
    '01.47': "Élevage de volailles",
    '01.47Z': "Élevage de volailles",
    '01.49': "Élevage d'autres animaux",
    '01.49Z': "Élevage d'autres animaux",
    '01.5': "Culture et élevage associés",
    '01.50': "Culture et élevage associés",
    '01.50Z': "Culture et élevage associés",
    '01.6': "Activités de soutien à l'agriculture et traitement primaire des récoltes",
    '01.61': "Activités de soutien aux cultures",
    '01.61Z': "Activités de soutien aux cultures",
    '01.62': "Activités de soutien à la production animale",
    '01.62Z': "Activités de soutien à la production animale",
    '01.63': "Traitement primaire des récoltes",
    '01.63Z': "Traitement primaire des récoltes",
    '01.64': "Traitement des semences",
    '01.64Z': "Traitement des semences",
    '01.7': "Chasse, piégeage et services annexes",
    '01.70': "Chasse, piégeage et services annexes",
    '01.70Z': "Chasse, piégeage et services annexes",
    '02': "Sylviculture et exploitation forestière",
    '02.1': "Sylviculture et autres activités forestières",
    '02.10': "Sylviculture et autres activités forestières",
    '02.10Z': "Sylviculture et autres activités forestières",
    '02.2': "Exploitation forestière",
    '02.20': "Exploitation forestière",
    '02.20Z': "Exploitation forestière",
    '02.3': "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
    '02.30': "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
    '02.30Z': "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
    '02.4': "Services de soutien à l'exploitation forestière",
    '02.40': "Services de soutien à l'exploitation forestière",
    '02.40Z': "Services de soutien à l'exploitation forestière",
    '03': "Pêche et aquaculture",
    '03.1': "Pêche",
    '03.11': "Pêche en mer",
    '03.11Z': "Pêche en mer",
    '03.12': "Pêche en eau douce",
    '03.12Z': "Pêche en eau douce",
    '03.2': "Aquaculture",
    '03.21': "Aquaculture en mer",
    '03.21Z': "Aquaculture en mer",
    '03.22': "Aquaculture en eau douce",
    '03.22Z': "Aquaculture en eau douce",
    'SECTION B': "INDUSTRIES EXTRACTIVES",
    '05': "Extraction de houille et de lignite",
    '05.1': "Extraction de houille",
    '05.10': "Extraction de houille",
    '05.10Z': "Extraction de houille",
    '05.2': "Extraction de lignite",
    '05.20': "Extraction de lignite",
    '05.20Z': "Extraction de lignite",
    '06': "Extraction d'hydrocarbures",
    '06.1': "Extraction de pétrole brut",
    '06.10': "Extraction de pétrole brut",
    '06.10Z': "Extraction de pétrole brut",
    '06.2': "Extraction de gaz naturel",
    '06.20': "Extraction de gaz naturel",
    '06.20Z': "Extraction de gaz naturel",
    '07': "Extraction de minerais métalliques",
    '07.1': "Extraction de minerais de fer",
    '07.10': "Extraction de minerais de fer",
    '07.10Z': "Extraction de minerais de fer",
    '07.2': "Extraction de minerais de métaux non ferreux",
    '07.21': "Extraction de minerais d'uranium et de thorium",
    '07.21Z': "Extraction de minerais d'uranium et de thorium",
    '07.29': "Extraction d'autres minerais de métaux non ferreux",
    '07.29Z': "Extraction d'autres minerais de métaux non ferreux",
    '08': "Autres industries extractives",
    '08.1': "Extraction de pierres, de sables et d'argiles",
    '08.11': "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise",
    '08.11Z': "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise",
    '08.12': "Exploitation de gravières et sablières, extraction d’argiles et de kaolin",
    '08.12Z': "Exploitation de gravières et sablières, extraction d’argiles et de kaolin",
    '08.9': "Activités extractives n.c.a.",
    '08.91': "Extraction des minéraux chimiques et d'engrais minéraux",
    '08.91Z': "Extraction des minéraux chimiques et d'engrais minéraux",
    '08.92': "Extraction de tourbe",
    '08.92Z': "Extraction de tourbe",
    '08.93': "Production de sel",
    '08.93Z': "Production de sel",
    '08.99': "Autres activités extractives n.c.a.",
    '08.99Z': "Autres activités extractives n.c.a.",
    '09': "Services de soutien aux industries extractives",
    '09.1': "Activités de soutien à l'extraction d'hydrocarbures",
    '09.10': "Activités de soutien à l'extraction d'hydrocarbures",
    '09.10Z': "Activités de soutien à l'extraction d'hydrocarbures",
    '09.9': "Activités de soutien aux autres industries extractives",
    '09.90': "Activités de soutien aux autres industries extractives",
    '09.90Z': "Activités de soutien aux autres industries extractives",
    'SECTION C': "INDUSTRIE MANUFACTURIÈRE",
    '10': "Industries alimentaires",
    '10.1': "Transformation et conservation de la viande et préparation de produits à base de viande",
    '10.11': "Transformation et conservation de la viande de boucherie",
    '10.11Z': "Transformation et conservation de la viande de boucherie",
    '10.12': "Transformation et conservation de la viande de volaille",
    '10.12Z': "Transformation et conservation de la viande de volaille",
    '10.13': "Préparation de produits à base de viande",
    '10.13A': "Préparation industrielle de produits à base de viande",
    '10.13B': "Charcuterie",
    '10.2': "Transformation et conservation de poisson, de crustacés et de mollusques",
    '10.20': "Transformation et conservation de poisson, de crustacés et de mollusques",
    '10.20Z': "Transformation et conservation de poisson, de crustacés et de mollusques",
    '10.3': "Transformation et conservation de fruits et légumes",
    '10.31': "Transformation et conservation de pommes de terre",
    '10.31Z': "Transformation et conservation de pommes de terre",
    '10.32': "Préparation de jus de fruits et légumes",
    '10.32Z': "Préparation de jus de fruits et légumes",
    '10.39': "Autre transformation et conservation de fruits et légumes",
    '10.39A': "Autre transformation et conservation de légumes",
    '10.39B': "Transformation et conservation de fruits",
    '10.4': "Fabrication d’huiles et graisses végétales et animales",
    '10.41': "Fabrication d'huiles et graisses",
    '10.41A': "Fabrication d'huiles et graisses brutes",
    '10.41B': "Fabrication d'huiles et graisses raffinées",
    '10.42': "Fabrication de margarine et graisses comestibles similaires",
    '10.42Z': "Fabrication de margarine et graisses comestibles similaires",
    '10.5': "Fabrication de produits laitiers",
    '10.51': "Exploitation de laiteries et fabrication de fromage",
    '10.51A': "Fabrication de lait liquide et de produits frais",
    '10.51B': "Fabrication de beurre",
    '10.51C': "Fabrication de fromage",
    '10.51D': "Fabrication d'autres produits laitiers",
    '10.52': "Fabrication de glaces et sorbets",
    '10.52Z': "Fabrication de glaces et sorbets",
    '10.6': "Travail des grains ; fabrication de produits amylacés",
    '10.61': "Travail des grains",
    '10.61A': "Meunerie",
    '10.61B': "Autres activités du travail des grains",
    '10.62': "Fabrication de produits amylacés",
    '10.62Z': "Fabrication de produits amylacés",
    '10.7': "Fabrication de produits de boulangerie-pâtisserie et de pâtes alimentaires",
    '10.71': "Fabrication de pain et de pâtisserie fraîche",
    '10.71A': "Fabrication industrielle de pain et de pâtisserie fraîche",
    '10.71B': "Cuisson de produits de boulangerie",
    '10.71C': "Boulangerie et boulangerie-pâtisserie",
    '10.71D': "Pâtisserie",
    '10.72': "Fabrication de biscuits, biscottes et pâtisseries de conservation",
    '10.72Z': "Fabrication de biscuits, biscottes et pâtisseries de conservation",
    '10.73': "Fabrication de pâtes alimentaires",
    '10.73Z': "Fabrication de pâtes alimentaires",
    '10.8': "Fabrication d'autres produits alimentaires",
    '10.81': "Fabrication de sucre",
    '10.81Z': "Fabrication de sucre",
    '10.82': "Fabrication de cacao, chocolat et de produits de confiserie",
    '10.82Z': "Fabrication de cacao, chocolat et de produits de confiserie",
    '10.83': "Transformation du thé et du café",
    '10.83Z': "Transformation du thé et du café",
    '10.84': "Fabrication de condiments et assaisonnements",
    '10.84Z': "Fabrication de condiments et assaisonnements",
    '10.85': "Fabrication de plats préparés",
    '10.85Z': "Fabrication de plats préparés",
    '10.86': "Fabrication d'aliments homogénéisés et diététiques",
    '10.86Z': "Fabrication d'aliments homogénéisés et diététiques",
    '10.89': "Fabrication d'autres produits alimentaires n.c.a.",
    '10.89Z': "Fabrication d'autres produits alimentaires n.c.a.",
    '10.9': "Fabrication d'aliments pour animaux",
    '10.91': "Fabrication d'aliments pour animaux de ferme",
    '10.91Z': "Fabrication d'aliments pour animaux de ferme",
    '10.92': "Fabrication d'aliments pour animaux de compagnie",
    '10.92Z': "Fabrication d'aliments pour animaux de compagnie",
    '11': "Fabrication de boissons",
    '11.0': "Fabrication de boissons",
    '11.01': "Production de boissons alcooliques distillées",
    '11.01Z': "Production de boissons alcooliques distillées",
    '11.02': "Production de vin (de raisin)",
    '11.02A': "Fabrication de vins effervescents",
    '11.02B': "Vinification",
    '11.03': "Fabrication de cidre et de vins de fruits ",
    '11.03Z': "Fabrication de cidre et de vins de fruits",
    '11.04': "Production d'autres boissons fermentées non distillées",
    '11.04Z': "Production d'autres boissons fermentées non distillées",
    '11.05': "Fabrication de bière",
    '11.05Z': "Fabrication de bière",
    '11.06': "Fabrication de malt",
    '11.06Z': "Fabrication de malt",
    '11.07': "Industrie des eaux minérales et autres eaux embouteillées et des boissons rafraîchissantes",
    '11.07A': "Industrie des eaux de table",
    '11.07B': "Production de boissons rafraîchissantes",
    '12': "Fabrication de produits à base de tabac",
    '12.0': "Fabrication de produits à base de tabac",
    '12.00': "Fabrication de produits à base de tabac",
    '12.00Z': "Fabrication de produits à base de tabac",
    '13': "Fabrication de textiles",
    '13.1': "Préparation de fibres textiles et filature",
    '13.10': "Préparation de fibres textiles et filature",
    '13.10Z': "Préparation de fibres textiles et filature",
    '13.2': "Tissage",
    '13.20': "Tissage",
    '13.20Z': "Tissage",
    '13.3': "Ennoblissement textile",
    '13.30': "Ennoblissement textile",
    '13.30Z': "Ennoblissement textile",
    '13.9': "Fabrication d'autres textiles",
    '13.91': "Fabrication d'étoffes à mailles",
    '13.91Z': "Fabrication d'étoffes à mailles",
    '13.92': "Fabrication d'articles textiles, sauf habillement",
    '13.92Z': "Fabrication d'articles textiles, sauf habillement",
    '13.93': "Fabrication de tapis et moquettes",
    '13.93Z': "Fabrication de tapis et moquettes",
    '13.94': "Fabrication de ficelles, cordes et filets",
    '13.94Z': "Fabrication de ficelles, cordes et filets",
    '13.95': "Fabrication de non-tissés, sauf habillement",
    '13.95Z': "Fabrication de non-tissés, sauf habillement",
    '13.96': "Fabrication d'autres textiles techniques et industriels",
    '13.96Z': "Fabrication d'autres textiles techniques et industriels",
    '13.99': "Fabrication d'autres textiles n.c.a.",
    '13.99Z': "Fabrication d'autres textiles n.c.a.",
    '14': "Industrie de l'habillement",
    '14.1': "Fabrication de vêtements, autres qu'en fourrure",
    '14.11': "Fabrication de vêtements en cuir",
    '14.11Z': "Fabrication de vêtements en cuir",
    '14.12': "Fabrication de vêtements de travail",
    '14.12Z': "Fabrication de vêtements de travail",
    '14.13': "Fabrication de vêtements de dessus",
    '14.13Z': "Fabrication de vêtements de dessus",
    '14.14': "Fabrication de vêtements de dessous",
    '14.14Z': "Fabrication de vêtements de dessous",
    '14.19': "Fabrication d'autres vêtements et accessoires",
    '14.19Z': "Fabrication d'autres vêtements et accessoires",
    '14.2': "Fabrication d'articles en fourrure",
    '14.20': "Fabrication d'articles en fourrure",
    '14.20Z': "Fabrication d'articles en fourrure",
    '14.3': "Fabrication d'articles à mailles",
    '14.31': "Fabrication d'articles chaussants à mailles",
    '14.31Z': "Fabrication d'articles chaussants à mailles",
    '14.39': "Fabrication d'autres articles à mailles",
    '14.39Z': "Fabrication d'autres articles à mailles",
    '15': "Industrie du cuir et de la chaussure",
    '15.1': "Apprêt et tannage des cuirs ; préparation et teinture des fourrures ; fabrication d'articles de voyage, de maroquinerie et de sellerie",
    '15.11': "Apprêt et tannage des cuirs ; préparation et teinture des fourrures",
    '15.11Z': "Apprêt et tannage des cuirs ; préparation et teinture des fourrures",
    '15.12': "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
    '15.12Z': "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
    '15.2': "Fabrication de chaussures",
    '15.20': "Fabrication de chaussures",
    '15.20Z': "Fabrication de chaussures",
    '16': "Travail du bois et fabrication d'articles en bois et en liège, à l’exception des meubles ; fabrication d’articles en vannerie et sparterie",
    '16.1': "Sciage et rabotage du bois",
    '16.10': "Sciage et rabotage du bois",
    '16.10A': "Sciage et rabotage du bois, hors imprégnation",
    '16.10B': "Imprégnation du bois",
    '16.2': "Fabrication d'articles en bois, liège, vannerie et sparterie",
    '16.21': "Fabrication de placage et de panneaux de bois",
    '16.21Z': "Fabrication de placage et de panneaux de bois",
    '16.22': "Fabrication de parquets assemblés",
    '16.22Z': "Fabrication de parquets assemblés",
    '16.23': "Fabrication de charpentes et d'autres menuiseries",
    '16.23Z': "Fabrication de charpentes et d'autres menuiseries",
    '16.24': "Fabrication d'emballages en bois",
    '16.24Z': "Fabrication d'emballages en bois",
    '16.29': "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie",
    '16.29Z': "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie",
    '17': "Industrie du papier et du carton",
    '17.1': "Fabrication de pâte à papier, de papier et de carton",
    '17.11': "Fabrication de pâte à papier",
    '17.11Z': "Fabrication de pâte à papier",
    '17.12': "Fabrication de papier et de carton",
    '17.12Z': "Fabrication de papier et de carton",
    '17.2': "Fabrication d'articles en papier ou en carton",
    '17.21': "Fabrication de papier et carton ondulés et d'emballages en papier ou en carton",
    '17.21A': "Fabrication de carton ondulé",
    '17.21B': "Fabrication de cartonnages",
    '17.21C': "Fabrication d'emballages en papier",
    '17.22': "Fabrication d'articles en papier à usage sanitaire ou domestique",
    '17.22Z': "Fabrication d'articles en papier à usage sanitaire ou domestique",
    '17.23': "Fabrication d'articles de papeterie",
    '17.23Z': "Fabrication d'articles de papeterie",
    '17.24': "Fabrication de papiers peints",
    '17.24Z': "Fabrication de papiers peints",
    '17.29': "Fabrication d'autres articles en papier ou en carton",
    '17.29Z': "Fabrication d'autres articles en papier ou en carton",
    '18': "Imprimerie et reproduction d'enregistrements",
    '18.1': "Imprimerie et services annexes",
    '18.11': "Imprimerie de journaux",
    '18.11Z': "Imprimerie de journaux",
    '18.12': "Autre imprimerie (labeur)",
    '18.12Z': "Autre imprimerie (labeur)",
    '18.13': "Activités de pré-presse",
    '18.13Z': "Activités de pré-presse",
    '18.14': "Reliure et activités connexes",
    '18.14Z': "Reliure et activités connexes",
    '18.2': "Reproduction d'enregistrements",
    '18.20': "Reproduction d'enregistrements",
    '18.20Z': "Reproduction d'enregistrements",
    '19': "Cokéfaction et raffinage",
    '19.1': "Cokéfaction",
    '19.10': "Cokéfaction",
    '19.10Z': "Cokéfaction",
    '19.2': "Raffinage du pétrole",
    '19.20': "Raffinage du pétrole",
    '19.20Z': "Raffinage du pétrole",
    '20': "Industrie chimique",
    '20.1': "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
    '20.11': "Fabrication de gaz industriels",
    '20.11Z': "Fabrication de gaz industriels",
    '20.12': "Fabrication de colorants et de pigments",
    '20.12Z': "Fabrication de colorants et de pigments",
    '20.13': "Fabrication d'autres produits chimiques inorganiques de base",
    '20.13A': "Enrichissement et  retraitement de matières nucléaires",
    '20.13B': "Fabrication d'autres produits chimiques inorganiques de base n.c.a.",
    '20.14': "Fabrication d'autres produits chimiques organiques de base",
    '20.14Z': "Fabrication d'autres produits chimiques organiques de base",
    '20.15': "Fabrication de produits azotés et d'engrais",
    '20.15Z': "Fabrication de produits azotés et d'engrais",
    '20.16': "Fabrication de matières plastiques de base",
    '20.16Z': "Fabrication de matières plastiques de base",
    '20.17': "Fabrication de caoutchouc synthétique",
    '20.17Z': "Fabrication de caoutchouc synthétique",
    '20.2': "Fabrication de pesticides et d’autres produits agrochimiques",
    '20.20': "Fabrication de pesticides et d’autres produits agrochimiques",
    '20.20Z': "Fabrication de pesticides et d’autres produits agrochimiques",
    '20.3': "Fabrication de peintures, vernis, encres et mastics",
    '20.30': "Fabrication de peintures, vernis, encres et mastics",
    '20.30Z': "Fabrication de peintures, vernis, encres et mastics",
    '20.4': "Fabrication de savons, de produits d'entretien et de parfums",
    '20.41': "Fabrication de savons, détergents et produits d'entretien",
    '20.41Z': "Fabrication de savons, détergents et produits d'entretien",
    '20.42': "Fabrication de parfums et de produits pour la toilette",
    '20.42Z': "Fabrication de parfums et de produits pour la toilette",
    '20.5': "Fabrication d'autres produits chimiques",
    '20.51': "Fabrication de produits explosifs",
    '20.51Z': "Fabrication de produits explosifs",
    '20.52': "Fabrication de colles",
    '20.52Z': "Fabrication de colles",
    '20.53': "Fabrication d'huiles essentielles",
    '20.53Z': "Fabrication d'huiles essentielles",
    '20.59': "Fabrication d'autres produits chimiques n.c.a.",
    '20.59Z': "Fabrication d'autres produits chimiques n.c.a.",
    '20.6': "Fabrication de fibres artificielles ou synthétiques",
    '20.60': "Fabrication de fibres artificielles ou synthétiques",
    '20.60Z': "Fabrication de fibres artificielles ou synthétiques",
    '21': "Industrie pharmaceutique",
    '21.1': "Fabrication de produits pharmaceutiques de base",
    '21.10': "Fabrication de produits pharmaceutiques de base",
    '21.10Z': "Fabrication de produits pharmaceutiques de base",
    '21.2': "Fabrication de préparations pharmaceutiques",
    '21.20': "Fabrication de préparations pharmaceutiques",
    '21.20Z': "Fabrication de préparations pharmaceutiques",
    '22': "Fabrication de produits en caoutchouc et en plastique",
    '22.1': "Fabrication de produits en caoutchouc",
    '22.11': "Fabrication et rechapage de pneumatiques",
    '22.11Z': "Fabrication et rechapage de pneumatiques",
    '22.19': "Fabrication d'autres articles en caoutchouc",
    '22.19Z': "Fabrication d'autres articles en caoutchouc",
    '22.2': "Fabrication  de produits en plastique",
    '22.21': "Fabrication de plaques, feuilles, tubes et profilés en matières plastiques",
    '22.21Z': "Fabrication de plaques, feuilles, tubes et profilés en matières plastiques",
    '22.22': "Fabrication d'emballages en matières plastiques",
    '22.22Z': "Fabrication d'emballages en matières plastiques",
    '22.23': "Fabrication d'éléments en matières plastiques pour la construction",
    '22.23Z': "Fabrication d'éléments en matières plastiques pour la construction",
    '22.29': "Fabrication d'autres articles en matières plastiques",
    '22.29A': "Fabrication de pièces techniques à base de matières plastiques",
    '22.29B': "Fabrication de produits de consommation courante en matières plastiques",
    '23': "Fabrication d'autres produits minéraux non métalliques",
    '23.1': "Fabrication de verre et d'articles en verre",
    '23.11': "Fabrication de verre plat",
    '23.11Z': "Fabrication de verre plat",
    '23.12': "Façonnage et transformation du verre plat",
    '23.12Z': "Façonnage et transformation du verre plat",
    '23.13': "Fabrication de verre creux",
    '23.13Z': "Fabrication de verre creux",
    '23.14': "Fabrication de fibres de verre",
    '23.14Z': "Fabrication de fibres de verre",
    '23.19': "Fabrication et façonnage d'autres articles en verre, y compris verre technique",
    '23.19Z': "Fabrication et façonnage d'autres articles en verre, y compris verre technique",
    '23.2': "Fabrication de produits réfractaires",
    '23.20': "Fabrication de produits réfractaires",
    '23.20Z': "Fabrication de produits réfractaires",
    '23.3': "Fabrication de matériaux de construction en terre cuite",
    '23.31': "Fabrication de carreaux en céramique",
    '23.31Z': "Fabrication de carreaux en céramique",
    '23.32': "Fabrication de briques, tuiles et produits de construction, en terre cuite",
    '23.32Z': "Fabrication de briques, tuiles et produits de construction, en terre cuite",
    '23.4': "Fabrication d'autres produits en céramique et en porcelaine",
    '23.41': "Fabrication d'articles céramiques à usage domestique ou ornemental",
    '23.41Z': "Fabrication d'articles céramiques à usage domestique ou ornemental",
    '23.42': "Fabrication d'appareils sanitaires en céramique",
    '23.42Z': "Fabrication d'appareils sanitaires en céramique",
    '23.43': "Fabrication d'isolateurs et pièces isolantes en céramique",
    '23.43Z': "Fabrication d'isolateurs et pièces isolantes en céramique",
    '23.44': "Fabrication d'autres produits céramiques à usage technique",
    '23.44Z': "Fabrication d'autres produits céramiques à usage technique",
    '23.49': "Fabrication d'autres produits céramiques",
    '23.49Z': "Fabrication d'autres produits céramiques",
    '23.5': "Fabrication de ciment, chaux et plâtre",
    '23.51': "Fabrication de ciment",
    '23.51Z': "Fabrication de ciment",
    '23.52': "Fabrication de chaux et plâtre",
    '23.52Z': "Fabrication de chaux et plâtre",
    '23.6': "Fabrication d'ouvrages en béton, en ciment ou en plâtre",
    '23.61': "Fabrication d'éléments en béton pour la construction",
    '23.61Z': "Fabrication d'éléments en béton pour la construction",
    '23.62': "Fabrication d'éléments en plâtre pour la construction",
    '23.62Z': "Fabrication d'éléments en plâtre pour la construction",
    '23.63': "Fabrication de béton prêt à l'emploi",
    '23.63Z': "Fabrication de béton prêt à l'emploi",
    '23.64': "Fabrication de mortiers et bétons secs",
    '23.64Z': "Fabrication de mortiers et bétons secs",
    '23.65': "Fabrication d'ouvrages en fibre-ciment",
    '23.65Z': "Fabrication d'ouvrages en fibre-ciment",
    '23.69': "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
    '23.69Z': "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
    '23.7': "Taille, façonnage et finissage de pierres",
    '23.70': "Taille, façonnage et finissage de pierres",
    '23.70Z': "Taille, façonnage et finissage de pierres",
    '23.9': "Fabrication de produits abrasifs et de produits minéraux non métalliques n.c.a.",
    '23.91': "Fabrication de produits abrasifs",
    '23.91Z': "Fabrication de produits abrasifs",
    '23.99': "Fabrication d'autres produits minéraux non métalliques n.c.a.",
    '23.99Z': "Fabrication d'autres produits minéraux non métalliques n.c.a.",
    '24': "Métallurgie",
    '24.1': "Sidérurgie",
    '24.10': "Sidérurgie",
    '24.10Z': "Sidérurgie",
    '24.2': "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier",
    '24.20': "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier",
    '24.20Z': "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier",
    '24.3': "Fabrication d'autres produits de première transformation de l'acier",
    '24.31': "Étirage à froid de barres",
    '24.31Z': "Étirage à froid de barres",
    '24.32': "Laminage à froid de feuillards",
    '24.32Z': "Laminage à froid de feuillards",
    '24.33': "Profilage à froid par formage ou pliage",
    '24.33Z': "Profilage à froid par formage ou pliage",
    '24.34': "Tréfilage à froid",
    '24.34Z': "Tréfilage à froid",
    '24.4': "Production de métaux précieux et d'autres métaux non ferreux",
    '24.41': "Production de métaux précieux",
    '24.41Z': "Production de métaux précieux",
    '24.42': "Métallurgie de l'aluminium",
    '24.42Z': "Métallurgie de l'aluminium",
    '24.43': "Métallurgie du plomb, du zinc ou de l'étain",
    '24.43Z': "Métallurgie du plomb, du zinc ou de l'étain",
    '24.44': "Métallurgie du cuivre",
    '24.44Z': "Métallurgie du cuivre",
    '24.45': "Métallurgie des autres métaux non ferreux",
    '24.45Z': "Métallurgie des autres métaux non ferreux",
    '24.46': "Élaboration et transformation de matières nucléaires",
    '24.46Z': "Élaboration et transformation de matières nucléaires",
    '24.5': "Fonderie",
    '24.51': "Fonderie de fonte",
    '24.51Z': "Fonderie de fonte",
    '24.52': "Fonderie d'acier",
    '24.52Z': "Fonderie d'acier",
    '24.53': "Fonderie de métaux légers",
    '24.53Z': "Fonderie de métaux légers",
    '24.54': "Fonderie d'autres métaux non ferreux",
    '24.54Z': "Fonderie d'autres métaux non ferreux",
    '25': "Fabrication de produits métalliques, à l’exception des machines et des équipements",
    '25.1': "Fabrication d'éléments en métal pour la construction",
    '25.11': "Fabrication de structures métalliques et de parties de structures",
    '25.11Z': "Fabrication de structures métalliques et de parties de structures",
    '25.12': "Fabrication de portes et fenêtres en métal",
    '25.12Z': "Fabrication de portes et fenêtres en métal",
    '25.2': "Fabrication de réservoirs, citernes et conteneurs métalliques",
    '25.21': "Fabrication de radiateurs et de chaudières pour le chauffage central",
    '25.21Z': "Fabrication de radiateurs et de chaudières pour le chauffage central",
    '25.29': "Fabrication d'autres réservoirs, citernes et conteneurs métalliques",
    '25.29Z': "Fabrication d'autres réservoirs, citernes et conteneurs métalliques",
    '25.3': "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
    '25.30': "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
    '25.30Z': "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
    '25.4': "Fabrication d'armes et de munitions",
    '25.40': "Fabrication d'armes et de munitions",
    '25.40Z': "Fabrication d'armes et de munitions",
    '25.5': "Forge, emboutissage, estampage ; métallurgie des poudres",
    '25.50': "Forge, emboutissage, estampage ; métallurgie des poudres",
    '25.50A': "Forge, estampage, matriçage ; métallurgie des poudres",
    '25.50B': "Découpage, emboutissage",
    '25.6': "Traitement et revêtement des métaux ; usinage",
    '25.61': "Traitement et revêtement des métaux",
    '25.61Z': "Traitement et revêtement des métaux",
    '25.62': "Usinage",
    '25.62A': "Décolletage",
    '25.62B': "Mécanique industrielle",
    '25.7': "Fabrication de coutellerie, d'outillage et de quincaillerie",
    '25.71': "Fabrication de coutellerie",
    '25.71Z': "Fabrication de coutellerie",
    '25.72': "Fabrication de serrures et de ferrures",
    '25.72Z': "Fabrication de serrures et de ferrures",
    '25.73': "Fabrication d'outillage",
    '25.73A': "Fabrication de moules et modèles",
    '25.73B': "Fabrication d'autres outillages",
    '25.9': "Fabrication d'autres ouvrages en métaux",
    '25.91': "Fabrication de fûts et emballages métalliques similaires",
    '25.91Z': "Fabrication de fûts et emballages métalliques similaires",
    '25.92': "Fabrication d'emballages métalliques légers",
    '25.92Z': "Fabrication d'emballages métalliques légers",
    '25.93': "Fabrication d'articles en fils métalliques, de chaînes et de ressorts",
    '25.93Z': "Fabrication d'articles en fils métalliques, de chaînes et de ressorts",
    '25.94': "Fabrication de vis et de boulons",
    '25.94Z': "Fabrication de vis et de boulons",
    '25.99': "Fabrication d'autres produits métalliques n.c.a.",
    '25.99A': "Fabrication d'articles métalliques ménagers",
    '25.99B': "Fabrication d'autres articles métalliques",
    '26': "Fabrication de produits informatiques, électroniques et optiques",
    '26.1': "Fabrication de composants et cartes électroniques",
    '26.11': "Fabrication de composants électroniques",
    '26.11Z': "Fabrication de composants électroniques",
    '26.12': "Fabrication de cartes électroniques assemblées",
    '26.12Z': "Fabrication de cartes électroniques assemblées",
    '26.2': "Fabrication d'ordinateurs et d'équipements périphériques",
    '26.20': "Fabrication d'ordinateurs et d'équipements périphériques",
    '26.20Z': "Fabrication d'ordinateurs et d'équipements périphériques",
    '26.3': "Fabrication d'équipements de communication",
    '26.30': "Fabrication d'équipements de communication",
    '26.30Z': "Fabrication d'équipements de communication",
    '26.4': "Fabrication de produits électroniques grand public",
    '26.40': "Fabrication de produits électroniques grand public",
    '26.40Z': "Fabrication de produits électroniques grand public",
    '26.5': "Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation ; horlogerie",
    '26.51': "Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation",
    '26.51A': "Fabrication d'équipements d'aide à la navigation",
    '26.51B': "Fabrication d'instrumentation scientifique et technique",
    '26.52': "Horlogerie",
    '26.52Z': "Horlogerie",
    '26.6': "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques",
    '26.60': "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques",
    '26.60Z': "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques",
    '26.7': "Fabrication de matériels optique et photographique",
    '26.70': "Fabrication de matériels optique et photographique",
    '26.70Z': "Fabrication de matériels optique et photographique",
    '26.8': "Fabrication de supports magnétiques et optiques",
    '26.80': "Fabrication de supports magnétiques et optiques",
    '26.80Z': "Fabrication de supports magnétiques et optiques",
    '27': "Fabrication d'équipements électriques",
    '27.1': "Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique",
    '27.11': "Fabrication de moteurs, génératrices et transformateurs électriques",
    '27.11Z': "Fabrication de moteurs, génératrices et transformateurs électriques",
    '27.12': "Fabrication de matériel de distribution et de commande électrique",
    '27.12Z': "Fabrication de matériel de distribution et de commande électrique",
    '27.2': "Fabrication de piles et d'accumulateurs électriques",
    '27.20': "Fabrication de piles et d'accumulateurs électriques",
    '27.20Z': "Fabrication de piles et d'accumulateurs électriques",
    '27.3': "Fabrication de fils et câbles et de matériel d'installation électrique",
    '27.31': "Fabrication de câbles de fibres optiques",
    '27.31Z': "Fabrication de câbles de fibres optiques",
    '27.32': "Fabrication d'autres fils et câbles électroniques ou électriques",
    '27.32Z': "Fabrication d'autres fils et câbles électroniques ou électriques",
    '27.33': "Fabrication de matériel d'installation électrique",
    '27.33Z': "Fabrication de matériel d'installation électrique",
    '27.4': "Fabrication d'appareils d'éclairage électrique",
    '27.40': "Fabrication d'appareils d'éclairage électrique",
    '27.40Z': "Fabrication d'appareils d'éclairage électrique",
    '27.5': "Fabrication d'appareils ménagers",
    '27.51': "Fabrication d'appareils électroménagers",
    '27.51Z': "Fabrication d'appareils électroménagers",
    '27.52': "Fabrication d'appareils ménagers non électriques",
    '27.52Z': "Fabrication d'appareils ménagers non électriques",
    '27.9': "Fabrication d'autres matériels électriques",
    '27.90': "Fabrication d'autres matériels électriques",
    '27.90Z': "Fabrication d'autres matériels électriques",
    '28': "Fabrication de machines et équipements n.c.a.",
    '28.1': "Fabrication de machines d'usage général",
    '28.11': "Fabrication de moteurs et turbines, à l'exception des moteurs d’avions et de véhicules",
    '28.11Z': "Fabrication de moteurs et turbines, à l'exception des moteurs d’avions et de véhicules",
    '28.12': "Fabrication d'équipements hydrauliques et pneumatiques",
    '28.12Z': "Fabrication d'équipements hydrauliques et pneumatiques",
    '28.13': "Fabrication d'autres pompes et compresseurs",
    '28.13Z': "Fabrication d'autres pompes et compresseurs",
    '28.14': "Fabrication d'autres articles de robinetterie",
    '28.14Z': "Fabrication d'autres articles de robinetterie",
    '28.15': "Fabrication d'engrenages et d'organes mécaniques de transmission",
    '28.15Z': "Fabrication d'engrenages et d'organes mécaniques de transmission",
    '28.2': "Fabrication d'autres machines d'usage général",
    '28.21': "Fabrication de fours et brûleurs",
    '28.21Z': "Fabrication de fours et brûleurs",
    '28.22': "Fabrication de matériel de levage et de manutention",
    '28.22Z': "Fabrication de matériel de levage et de manutention",
    '28.23': "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)",
    '28.23Z': "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)",
    '28.24': "Fabrication d'outillage portatif à moteur incorporé",
    '28.24Z': "Fabrication d'outillage portatif à moteur incorporé",
    '28.25': "Fabrication d'équipements aérauliques et frigorifiques industriels",
    '28.25Z': "Fabrication d'équipements aérauliques et frigorifiques industriels",
    '28.29': "Fabrication de machines diverses d'usage général",
    '28.29A': "Fabrication d'équipements d'emballage, de conditionnement et de pesage",
    '28.29B': "Fabrication d'autres machines d'usage général",
    '28.3': "Fabrication de machines agricoles et forestières",
    '28.30': "Fabrication de machines agricoles et forestières",
    '28.30Z': "Fabrication de machines agricoles et forestières",
    '28.4': "Fabrication de machines de formage des métaux et de machines-outils",
    '28.41': "Fabrication de machines de formage des métaux",
    '28.41Z': "Fabrication de machines-outils pour le travail des métaux",
    '28.49': "Fabrication d'autres machines-outils",
    '28.49Z': "Fabrication d'autres machines-outils",
    '28.9': "Fabrication d'autres machines d'usage spécifique",
    '28.91': "Fabrication de machines pour la métallurgie",
    '28.91Z': "Fabrication de machines pour la métallurgie",
    '28.92': "Fabrication de machines pour l'extraction ou la construction",
    '28.92Z': "Fabrication de machines pour l'extraction ou la construction",
    '28.93': "Fabrication de machines pour l'industrie agro-alimentaire",
    '28.93Z': "Fabrication de machines pour l'industrie agro-alimentaire",
    '28.94': "Fabrication de machines pour les industries textiles",
    '28.94Z': "Fabrication de machines pour les industries textiles",
    '28.95': "Fabrication de machines pour les industries du papier et du carton",
    '28.95Z': "Fabrication de machines pour les industries du papier et du carton",
    '28.96': "Fabrication de machines pour le travail du caoutchouc ou des plastiques",
    '28.96Z': "Fabrication de machines pour le travail du caoutchouc ou des plastiques",
    '28.99': "Fabrication d'autres machines d'usage spécifique n.c.a.",
    '28.99A': "Fabrication de machines d'imprimerie",
    '28.99B': "Fabrication d'autres machines spécialisées",
    '29': "Industrie automobile",
    '29.1': "Construction de véhicules automobiles",
    '29.10': "Construction de véhicules automobiles",
    '29.10Z': "Construction de véhicules automobiles",
    '29.2': "Fabrication de carrosseries et remorques",
    '29.20': "Fabrication de carrosseries et remorques",
    '29.20Z': "Fabrication de carrosseries et remorques",
    '29.3': "Fabrication d'équipements automobiles",
    '29.31': "Fabrication d'équipements électriques et électroniques automobiles",
    '29.31Z': "Fabrication d'équipements électriques et électroniques automobiles",
    '29.32': "Fabrication d'autres équipements automobiles",
    '29.32Z': "Fabrication d'autres équipements automobiles",
    '30': "Fabrication d'autres matériels de transport",
    '30.1': "Construction navale",
    '30.11': "Construction de navires et de structures flottantes",
    '30.11Z': "Construction de navires et de structures flottantes",
    '30.12': "Construction de bateaux de plaisance",
    '30.12Z': "Construction de bateaux de plaisance",
    '30.2': "Construction de locomotives et d'autre matériel ferroviaire roulant",
    '30.20': "Construction de locomotives et d'autre matériel ferroviaire roulant",
    '30.20Z': "Construction de locomotives et d'autre matériel ferroviaire roulant",
    '30.3': "Construction aéronautique et spatiale",
    '30.30': "Construction aéronautique et spatiale",
    '30.30Z': "Construction aéronautique et spatiale",
    '30.4': "Construction de véhicules militaires de combat",
    '30.40': "Construction de véhicules militaires de combat",
    '30.40Z': "Construction de véhicules militaires de combat",
    '30.9': "Fabrication de matériels de transport n.c.a.",
    '30.91': "Fabrication de motocycles",
    '30.91Z': "Fabrication de motocycles",
    '30.92': "Fabrication de bicyclettes et de véhicules pour invalides",
    '30.92Z': "Fabrication de bicyclettes et de véhicules pour invalides",
    '30.99': "Fabrication d’autres équipements de transport n.c.a.",
    '30.99Z': "Fabrication d’autres équipements de transport n.c.a.",
    '31': "Fabrication de meubles",
    '31.0': "Fabrication de meubles",
    '31.01': "Fabrication de meubles de bureau et de magasin",
    '31.01Z': "Fabrication de meubles de bureau et de magasin",
    '31.02': "Fabrication de meubles de cuisine",
    '31.02Z': "Fabrication de meubles de cuisine",
    '31.03': "Fabrication de matelas",
    '31.03Z': "Fabrication de matelas",
    '31.09': "Fabrication d'autres meubles",
    '31.09A': "Fabrication de sièges d'ameublement d'intérieur",
    '31.09B': "Fabrication d’autres meubles et industries connexes de l’ameublement",
    '32': "Autres industries manufacturières",
    '32.1': "Fabrication d’articles de joaillerie, bijouterie et articles similaires",
    '32.11': "Frappe de monnaie",
    '32.11Z': "Frappe de monnaie",
    '32.12': "Fabrication d’articles de joaillerie et bijouterie",
    '32.12Z': "Fabrication d’articles de joaillerie et bijouterie",
    '32.13': "Fabrication d’articles de bijouterie fantaisie et articles similaires",
    '32.13Z': "Fabrication d’articles de bijouterie fantaisie et articles similaires",
    '32.2': "Fabrication d'instruments de musique",
    '32.20': "Fabrication d'instruments de musique",
    '32.20Z': "Fabrication d'instruments de musique",
    '32.3': "Fabrication d'articles de sport",
    '32.30': "Fabrication d'articles de sport",
    '32.30Z': "Fabrication d'articles de sport",
    '32.4': "Fabrication de jeux et jouets",
    '32.40': "Fabrication de jeux et jouets",
    '32.40Z': "Fabrication de jeux et jouets",
    '32.5': "Fabrication d'instruments et de fournitures à usage médical et dentaire",
    '32.50': "Fabrication d'instruments et de fournitures à usage médical et dentaire",
    '32.50A': "Fabrication de matériel médico-chirurgical et dentaire",
    '32.50B': "Fabrication de lunettes",
    '32.9': "Activités manufacturières n.c.a.",
    '32.91': "Fabrication d’articles de brosserie",
    '32.91Z': "Fabrication d’articles de brosserie",
    '32.99': "Autres activités manufacturières n.c.a.",
    '32.99Z': "Autres activités manufacturières n.c.a.",
    '33': "Réparation et installation de machines et d'équipements",
    '33.1': "Réparation d'ouvrages en métaux, de machines et d'équipements",
    '33.11': "Réparation d'ouvrages en métaux",
    '33.11Z': "Réparation d'ouvrages en métaux",
    '33.12': "Réparation de machines et équipements mécaniques",
    '33.12Z': "Réparation de machines et équipements mécaniques",
    '33.13': "Réparation de matériels électroniques et optiques",
    '33.13Z': "Réparation de matériels électroniques et optiques",
    '33.14': "Réparation d'équipements électriques",
    '33.14Z': "Réparation d'équipements électriques",
    '33.15': "Réparation et maintenance navale",
    '33.15Z': "Réparation et maintenance navale",
    '33.16': "Réparation et maintenance d'aéronefs et d'engins spatiaux",
    '33.16Z': "Réparation et maintenance d'aéronefs et d'engins spatiaux",
    '33.17': "Réparation et maintenance d'autres équipements de transport",
    '33.17Z': "Réparation et maintenance d'autres équipements de transport",
    '33.19': "Réparation d'autres équipements",
    '33.19Z': "Réparation d'autres équipements",
    '33.2': "Installation de machines et d'équipements industriels",
    '33.20': "Installation de machines et d'équipements industriels",
    '33.20A': "Installation de structures métalliques, chaudronnées et de tuyauterie",
    '33.20B': "Installation de machines et équipements mécaniques",
    '33.20C': "Conception d'ensemble et assemblage sur site industriel d'équipements de contrôle des processus industriels",
    '33.20D': "Installation d'équipements électriques, de matériels électroniques et optiques ou d'autres matériels",
    'SECTION D': "PRODUCTION ET DISTRIBUTION D'ÉLECTRICITÉ, DE GAZ, DE VAPEUR ET D'AIR CONDITIONNÉ",
    '35': "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
    '35.1': "Production, transport et distribution d'électricité",
    '35.11': "Production d'électricité",
    '35.11Z': "Production d'électricité",
    '35.12': "Transport d'électricité",
    '35.12Z': "Transport d'électricité",
    '35.13': "Distribution d'électricité",
    '35.13Z': "Distribution d'électricité",
    '35.14': "Commerce d'électricité",
    '35.14Z': "Commerce d'électricité",
    '35.2': "Production et distribution de combustibles gazeux",
    '35.21': "Production de combustibles gazeux",
    '35.21Z': "Production de combustibles gazeux",
    '35.22': "Distribution de combustibles gazeux par conduites",
    '35.22Z': "Distribution de combustibles gazeux par conduites",
    '35.23': "Commerce de combustibles gazeux par conduites",
    '35.23Z': "Commerce de combustibles gazeux par conduites",
    '35.3': "Production et distribution de vapeur et d'air conditionné",
    '35.30': "Production et distribution de vapeur et d'air conditionné",
    '35.30Z': "Production et distribution de vapeur et d'air conditionné",
    'SECTION E': "PRODUCTION ET DISTRIBUTION D'EAU ; ASSAINISSEMENT, GESTION DES DÉCHETS ET DÉPOLLUTION",
    '36': "Captage, traitement et distribution d'eau",
    '36.0': "Captage, traitement et distribution d'eau",
    '36.00': "Captage, traitement et distribution d'eau",
    '36.00Z': "Captage, traitement et distribution d'eau",
    '37': "Collecte et traitement des eaux usées",
    '37.0': "Collecte et traitement des eaux usées",
    '37.00': "Collecte et traitement des eaux usées",
    '37.00Z': "Collecte et traitement des eaux usées",
    '38': "Collecte, traitement et élimination des déchets ; récupération",
    '38.1': "Collecte des déchets",
    '38.11': "Collecte des déchets non dangereux",
    '38.11Z': "Collecte des déchets non dangereux",
    '38.12': "Collecte des déchets dangereux",
    '38.12Z': "Collecte des déchets dangereux",
    '38.2': "Traitement et élimination des déchets",
    '38.21': "Traitement et élimination des déchets non dangereux",
    '38.21Z': "Traitement et élimination des déchets non dangereux",
    '38.22': "Traitement et élimination des déchets dangereux",
    '38.22Z': "Traitement et élimination des déchets dangereux",
    '38.3': "Récupération",
    '38.31': "Démantèlement d'épaves",
    '38.31Z': "Démantèlement d'épaves",
    '38.32': "Récupération de déchets triés",
    '38.32Z': "Récupération de déchets triés",
    '39': "Dépollution et autres services de gestion des déchets",
    '39.0': "Dépollution et autres services de gestion des déchets",
    '39.00': "Dépollution et autres services de gestion des déchets",
    '39.00Z': "Dépollution et autres services de gestion des déchets",
    'SECTION F': "CONSTRUCTION",
    '41': "Construction de bâtiments",
    '41.1': "Promotion immobilière",
    '41.10': "Promotion immobilière",
    '41.10A': "Promotion immobilière de logements",
    '41.10B': "Promotion immobilière de bureaux",
    '41.10C': "Promotion immobilière d'autres bâtiments",
    '41.10D': "Supports juridiques de programmes",
    '41.2': "Construction de bâtiments résidentiels et non résidentiels",
    '41.20': "Construction de bâtiments résidentiels et non résidentiels",
    '41.20A': "Construction de maisons individuelles",
    '41.20B': "Construction d'autres bâtiments",
    '42': "Génie civil",
    '42.1': "Construction de routes et de voies ferrées",
    '42.11': "Construction de routes et autoroutes",
    '42.11Z': "Construction de routes et autoroutes",
    '42.12': "Construction de voies ferrées de surface et souterraines",
    '42.12Z': "Construction de voies ferrées de surface et souterraines",
    '42.13': "Construction de ponts et tunnels",
    '42.13A': "Construction d'ouvrages d'art",
    '42.13B': "Construction et entretien de tunnels",
    '42.2': "Construction de réseaux et de lignes",
    '42.21': "Construction de réseaux pour fluides",
    '42.21Z': "Construction de réseaux pour fluides",
    '42.22': "Construction de réseaux électriques et de télécommunications",
    '42.22Z': "Construction de réseaux électriques et de télécommunications",
    '42.9': "Construction d'autres ouvrages de génie civil",
    '42.91': "Construction d'ouvrages maritimes et fluviaux",
    '42.91Z': "Construction d'ouvrages maritimes et fluviaux",
    '42.99': "Construction d'autres ouvrages de génie civil n.c.a.",
    '42.99Z': "Construction d'autres ouvrages de génie civil n.c.a.",
    '43': "Travaux de construction spécialisés",
    '43.1': "Démolition et préparation des sites",
    '43.11': "Travaux de démolition",
    '43.11Z': "Travaux de démolition",
    '43.12': "Travaux de préparation des sites",
    '43.12A': "Travaux de terrassement courants et travaux préparatoires",
    '43.12B': "Travaux de terrassement spécialisés ou de grande masse",
    '43.13': "Forages et sondages",
    '43.13Z': "Forages et sondages",
    '43.2': "Travaux d'installation électrique, plomberie et autres travaux d'installation",
    '43.21': "Installation électrique",
    '43.21A': "Travaux d'installation électrique dans tous locaux",
    '43.21B': "Travaux d'installation électrique sur la voie publique",
    '43.22': "Travaux de plomberie et installation de chauffage et de conditionnement d'air",
    '43.22A': "Travaux d'installation d'eau et de gaz en tous locaux",
    '43.22B': "Travaux d'installation d'équipements thermiques et de climatisation",
    '43.29': "Autres travaux d'installation",
    '43.29A': "Travaux d'isolation",
    '43.29B': "Autres travaux d'installation n.c.a.",
    '43.3': "Travaux de finition",
    '43.31': "Travaux de plâtrerie",
    '43.31Z': "Travaux de plâtrerie",
    '43.32': "Travaux de menuiserie",
    '43.32A': "Travaux de menuiserie bois et PVC",
    '43.32B': "Travaux de menuiserie métallique et serrurerie",
    '43.32C': "Agencement de lieux de vente",
    '43.33': "Travaux de revêtement des sols et des murs",
    '43.33Z': "Travaux de revêtement des sols et des murs",
    '43.34': "Travaux de peinture et vitrerie",
    '43.34Z': "Travaux de peinture et vitrerie",
    '43.39': "Autres travaux de finition",
    '43.39Z': "Autres travaux de finition",
    '43.9': "Autres travaux de construction spécialisés",
    '43.91': "Travaux de couverture",
    '43.91A': "Travaux de charpente",
    '43.91B': "Travaux de couverture par éléments",
    '43.99': "Autres travaux de construction spécialisés n.c.a.",
    '43.99A': "Travaux d'étanchéification",
    '43.99B': "Travaux de montage de structures métalliques",
    '43.99C': "Travaux de maçonnerie générale et gros œuvre de bâtiment",
    '43.99D': "Autres travaux spécialisés de construction",
    '43.99E': "Location avec opérateur de matériel de construction",
    'SECTION G': "COMMERCE ; RÉPARATION D'AUTOMOBILES ET DE MOTOCYCLES",
    '45': "Commerce et réparation d'automobiles et de motocycles",
    '45.1': "Commerce de véhicules automobiles",
    '45.11': "Commerce de voitures et de véhicules automobiles légers",
    '45.11Z': "Commerce de voitures et de véhicules automobiles légers",
    '45.19': "Commerce d'autres véhicules automobiles",
    '45.19Z': "Commerce d'autres véhicules automobiles",
    '45.2': "Entretien et réparation de véhicules automobiles",
    '45.20': "Entretien et réparation de véhicules automobiles",
    '45.20A': "Entretien et réparation de véhicules automobiles légers",
    '45.20B': "Entretien et réparation d'autres véhicules automobiles",
    '45.3': "Commerce d'équipements automobiles",
    '45.31': "Commerce de gros d'équipements automobiles",
    '45.31Z': "Commerce de gros d'équipements automobiles",
    '45.32': "Commerce de détail d'équipements automobiles",
    '45.32Z': "Commerce de détail d'équipements automobiles",
    '45.4': "Commerce et réparation de motocycles",
    '45.40': "Commerce et réparation de motocycles",
    '45.40Z': "Commerce et réparation de motocycles",
    '46': "Commerce de gros, à l’exception des automobiles et des motocycles",
    '46.1': "Intermédiaires du commerce de gros",
    '46.11': "Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis",
    '46.11Z': "Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis",
    '46.12': "Intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques",
    '46.12A': "Centrales d'achat de carburant",
    '46.12B': "Autres intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques",
    '46.13': "Intermédiaires du commerce en bois et matériaux de construction",
    '46.13Z': "Intermédiaires du commerce en bois et matériaux de construction",
    '46.14': "Intermédiaires du commerce en machines, équipements industriels, navires et avions",
    '46.14Z': "Intermédiaires du commerce en machines, équipements industriels, navires et avions",
    '46.15': "Intermédiaires du commerce en meubles, articles de ménage et quincaillerie",
    '46.15Z': "Intermédiaires du commerce en meubles, articles de ménage et quincaillerie",
    '46.16': "Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir",
    '46.16Z': "Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir",
    '46.17': "Intermédiaires du commerce en denrées, boissons et tabac",
    '46.17A': "Centrales d'achat alimentaires",
    '46.17B': "Autres intermédiaires du commerce en denrées, boissons et tabac",
    '46.18': "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques",
    '46.18Z': "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques",
    '46.19': "Intermédiaires du commerce en produits divers",
    '46.19A': "Centrales d'achat non alimentaires",
    '46.19B': "Autres intermédiaires du commerce en produits divers",
    '46.2': "Commerce de gros de produits agricoles bruts et d'animaux vivants",
    '46.21': "Commerce de gros de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail",
    '46.21Z': "Commerce de gros (commerce interentreprises) de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail",
    '46.22': "Commerce de gros de fleurs et plantes",
    '46.22Z': "Commerce de gros (commerce interentreprises) de fleurs et plantes",
    '46.23': "Commerce de gros d'animaux vivants",
    '46.23Z': "Commerce de gros (commerce interentreprises) d'animaux vivants",
    '46.24': "Commerce de gros de cuirs et peaux",
    '46.24Z': "Commerce de gros (commerce interentreprises) de cuirs et peaux",
    '46.3': "Commerce de gros de produits alimentaires, de boissons et de tabac",
    '46.31': "Commerce de gros de fruits et légumes",
    '46.31Z': "Commerce de gros (commerce interentreprises) de fruits et légumes",
    '46.32': "Commerce de gros de viandes et de produits à base de viande",
    '46.32A': "Commerce de gros (commerce interentreprises) de viandes de boucherie",
    '46.32B': "Commerce de gros (commerce interentreprises) de produits à base de viande",
    '46.32C': "Commerce de gros (commerce interentreprises) de volailles et gibier",
    '46.33': "Commerce de gros de produits laitiers, œufs, huiles et matières grasses comestibles",
    '46.33Z': "Commerce de gros (commerce interentreprises) de produits laitiers, œufs, huiles et matières grasses comestibles",
    '46.34': "Commerce de gros de boissons",
    '46.34Z': "Commerce de gros (commerce interentreprises) de boissons",
    '46.35': "Commerce de gros de produits à base de tabac",
    '46.35Z': "Commerce de gros (commerce interentreprises) de produits à base de tabac",
    '46.36': "Commerce de gros de sucre, chocolat et confiserie",
    '46.36Z': "Commerce de gros (commerce interentreprises) de sucre, chocolat et confiserie",
    '46.37': "Commerce de gros de café, thé, cacao et épices",
    '46.37Z': "Commerce de gros (commerce interentreprises) de café, thé, cacao et épices",
    '46.38': "Commerce de gros d'autres produits alimentaires, y compris poissons, crustacés et mollusques",
    '46.38A': "Commerce de gros (commerce interentreprises) de poissons, crustacés et mollusques",
    '46.38B': "Commerce de gros (commerce interentreprises) alimentaire spécialisé divers",
    '46.39': "Commerce de gros non spécialisé de denrées, boissons et tabac",
    '46.39A': "Commerce de gros (commerce interentreprises) de produits surgelés",
    '46.39B': "Commerce de gros (commerce interentreprises) alimentaire non spécialisé",
    '46.4': "Commerce de gros de biens domestiques",
    '46.41': "Commerce de gros de textiles",
    '46.41Z': "Commerce de gros (commerce interentreprises) de textiles",
    '46.42': "Commerce de gros d'habillement et de chaussures",
    '46.42Z': "Commerce de gros (commerce interentreprises) d'habillement et de chaussures",
    '46.43': "Commerce de gros d'appareils électroménagers",
    '46.43Z': "Commerce de gros (commerce interentreprises) d'appareils électroménagers",
    '46.44': "Commerce de gros de vaisselle, verrerie et produits d'entretien",
    '46.44Z': "Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d'entretien",
    '46.45': "Commerce de gros de parfumerie et de produits de beauté",
    '46.45Z': "Commerce de gros (commerce interentreprises) de parfumerie et de produits de beauté",
    '46.46': "Commerce de gros de produits pharmaceutiques",
    '46.46Z': "Commerce de gros (commerce interentreprises) de produits pharmaceutiques",
    '46.47': "Commerce de gros de meubles, de tapis et d'appareils d'éclairage",
    '46.47Z': "Commerce de gros (commerce interentreprises) de meubles, de tapis et d'appareils d'éclairage",
    '46.48': "Commerce de gros d'articles d'horlogerie et de bijouterie",
    '46.48Z': "Commerce de gros (commerce interentreprises) d'articles d'horlogerie et de bijouterie",
    '46.49': "Commerce de gros d'autres biens domestiques",
    '46.49Z': "Commerce de gros (commerce interentreprises) d'autres biens domestiques",
    '46.5': "Commerce de gros d'équipements de l'information et de la communication",
    '46.51': "Commerce de gros d'ordinateurs, d'équipements informatiques périphériques et de logiciels",
    '46.51Z': "Commerce de gros (commerce interentreprises) d'ordinateurs, d'équipements informatiques périphériques et de logiciels",
    '46.52': "Commerce de gros de composants et d'équipements électroniques et de télécommunication",
    '46.52Z': "Commerce de gros (commerce interentreprises) de composants et d'équipements électroniques et de télécommunication",
    '46.6': "Commerce de gros d'autres équipements industriels",
    '46.61': "Commerce de gros de matériel agricole",
    '46.61Z': "Commerce de gros (commerce interentreprises) de matériel agricole",
    '46.62': "Commerce de gros de machines-outils",
    '46.62Z': "Commerce de gros (commerce interentreprises) de machines-outils",
    '46.63': "Commerce de gros de machines pour l'extraction, la construction et le génie civil",
    '46.63Z': "Commerce de gros (commerce interentreprises) de machines pour l'extraction, la construction et le génie civil",
    '46.64': "Commerce de gros de machines pour l'industrie textile et l'habillement",
    '46.64Z': "Commerce de gros (commerce interentreprises) de machines pour l'industrie textile et l'habillement",
    '46.65': "Commerce de gros de mobilier de bureau",
    '46.65Z': "Commerce de gros (commerce interentreprises) de mobilier de bureau",
    '46.66': "Commerce de gros d'autres machines et équipements de bureau",
    '46.66Z': "Commerce de gros (commerce interentreprises) d'autres machines et équipements de bureau",
    '46.69': "Commerce de gros d'autres machines et équipements",
    '46.69A': "Commerce de gros (commerce interentreprises) de matériel électrique",
    '46.69B': "Commerce de gros (commerce interentreprises) de fournitures et équipements industriels divers",
    '46.69C': "Commerce de gros (commerce interentreprises) de fournitures et équipements divers pour le commerce et les services",
    '46.7': "Autres commerces de gros spécialisés",
    '46.71': "Commerce de gros de combustibles et de produits annexes",
    '46.71Z': "Commerce de gros (commerce interentreprises) de combustibles et de produits annexes",
    '46.72': "Commerce de gros de minerais et métaux",
    '46.72Z': "Commerce de gros (commerce interentreprises) de minerais et métaux",
    '46.73': "Commerce de gros de bois, de matériaux de construction et d'appareils sanitaires",
    '46.73A': "Commerce de gros (commerce interentreprises) de bois et de matériaux de construction",
    '46.73B': "Commerce de gros (commerce interentreprises) d'appareils sanitaires et de produits de décoration",
    '46.74': "Commerce de gros de quincaillerie et fournitures pour plomberie et chauffage",
    '46.74A': "Commerce de gros (commerce interentreprises) de quincaillerie",
    '46.74B': "Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage",
    '46.75': "Commerce de gros de produits chimiques",
    '46.75Z': "Commerce de gros (commerce interentreprises) de produits chimiques",
    '46.76': "Commerce de gros d'autres produits intermédiaires",
    '46.76Z': "Commerce de gros (commerce interentreprises) d'autres produits intermédiaires",
    '46.77': "Commerce de gros de déchets et débris",
    '46.77Z': "Commerce de gros (commerce interentreprises) de déchets et débris",
    '46.9': "Commerce de gros non spécialisé",
    '46.90': "Commerce de gros non spécialisé",
    '46.90Z': "Commerce de gros (commerce interentreprises) non spécialisé",
    '47': "Commerce de détail, à l’exception des automobiles et des motocycles",
    '47.1': "Commerce de détail en magasin non spécialisé",
    '47.11': "Commerce de détail en magasin non spécialisé à prédominance alimentaire",
    '47.11A': "Commerce de détail de produits surgelés",
    '47.11B': "Commerce d'alimentation générale",
    '47.11C': "Supérettes",
    '47.11D': "Supermarchés",
    '47.11E': "Magasins multi-commerces",
    '47.11F': "Hypermarchés",
    '47.19': "Autre commerce de détail en magasin non spécialisé",
    '47.19A': "Grands magasins",
    '47.19B': "Autres commerces de détail en magasin non spécialisé",
    '47.2': "Commerce de détail alimentaire en magasin spécialisé",
    '47.21': "Commerce de détail de fruits et légumes en magasin spécialisé",
    '47.21Z': "Commerce de détail de fruits et légumes en magasin spécialisé",
    '47.22': "Commerce de détail de viandes et de produits à base de viande en magasin spécialisé",
    '47.22Z': "Commerce de détail de viandes et de produits à base de viande en magasin spécialisé",
    '47.23': "Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé",
    '47.23Z': "Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé",
    '47.24': "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé",
    '47.24Z': "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé",
    '47.25': "Commerce de détail de boissons en magasin spécialisé",
    '47.25Z': "Commerce de détail de boissons en magasin spécialisé",
    '47.26': "Commerce de détail de produits à base de tabac en magasin spécialisé",
    '47.26Z': "Commerce de détail de produits à base de tabac en magasin spécialisé",
    '47.29': "Autres commerces de détail alimentaires en magasin spécialisé",
    '47.29Z': "Autres commerces de détail alimentaires en magasin spécialisé",
    '47.3': "Commerce de détail de carburants en magasin spécialisé",
    '47.30': "Commerce de détail de carburants en magasin spécialisé",
    '47.30Z': "Commerce de détail de carburants en magasin spécialisé",
    '47.4': "Commerce de détail d'équipements de l'information et de la communication en magasin spécialisé",
    '47.41': "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé",
    '47.41Z': "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé",
    '47.42': "Commerce de détail de matériels de télécommunication en magasin spécialisé",
    '47.42Z': "Commerce de détail de matériels de télécommunication en magasin spécialisé",
    '47.43': "Commerce de détail de matériels audio/vidéo en magasin spécialisé",
    '47.43Z': "Commerce de détail de matériels audio et vidéo en magasin spécialisé",
    '47.5': "Commerce de détail d'autres équipements du foyer en magasin spécialisé",
    '47.51': "Commerce de détail de textiles en magasin spécialisé",
    '47.51Z': "Commerce de détail de textiles en magasin spécialisé",
    '47.52': "Commerce de détail de quincaillerie, peintures et verres en magasin spécialisé",
    '47.52A': "Commerce de détail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m2)",
    '47.52B': "Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m2et plus)",
    '47.53': "Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé",
    '47.53Z': "Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé",
    '47.54': "Commerce de détail d'appareils électroménagers en magasin spécialisé",
    '47.54Z': "Commerce de détail d'appareils électroménagers en magasin spécialisé",
    '47.59': "Commerce de détail de meubles, appareils d'éclairage et autres articles de ménage en magasin spécialisé",
    '47.59A': "Commerce de détail de meubles",
    '47.59B': "Commerce de détail d'autres équipements du foyer",
    '47.6': "Commerce de détail de biens culturels et de loisirs en magasin spécialisé",
    '47.61': "Commerce de détail de livres en magasin spécialisé",
    '47.61Z': "Commerce de détail de livres en magasin spécialisé",
    '47.62': "Commerce de détail de journaux et papeterie en magasin spécialisé",
    '47.62Z': "Commerce de détail de journaux et papeterie en magasin spécialisé",
    '47.63': "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé",
    '47.63Z': "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé",
    '47.64': "Commerce de détail d'articles de sport en magasin spécialisé",
    '47.64Z': "Commerce de détail d'articles de sport en magasin spécialisé",
    '47.65': "Commerce de détail de jeux et jouets en magasin spécialisé",
    '47.65Z': "Commerce de détail de jeux et jouets en magasin spécialisé",
    '47.7': "Autres commerces de détail en magasin spécialisé",
    '47.71': "Commerce de détail d'habillement en magasin spécialisé",
    '47.71Z': "Commerce de détail d'habillement en magasin spécialisé",
    '47.72': "Commerce de détail de chaussures et d'articles en cuir en magasin spécialisé",
    '47.72A': "Commerce de détail de la chaussure",
    '47.72B': "Commerce de détail de maroquinerie et d'articles de voyage",
    '47.73': "Commerce de détail de produits pharmaceutiques en magasin spécialisé",
    '47.73Z': "Commerce de détail de produits pharmaceutiques en magasin spécialisé",
    '47.74': "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé",
    '47.74Z': "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé",
    '47.75': "Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé",
    '47.75Z': "Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé",
    '47.76': "Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé",
    '47.76Z': "Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé",
    '47.77': "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé",
    '47.77Z': "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé",
    '47.78': "Autre commerce de détail de biens neufs en magasin spécialisé",
    '47.78A': "Commerces de détail d'optique",
    '47.78B': "Commerces de détail de charbons et combustibles",
    '47.78C': "Autres commerces de détail spécialisés divers",
    '47.79': "Commerce de détail de biens d'occasion en magasin",
    '47.79Z': "Commerce de détail de biens d'occasion en magasin",
    '47.8': "Commerce de détail sur éventaires et marchés",
    '47.81': "Commerce de détail alimentaire sur éventaires et marchés",
    '47.81Z': "Commerce de détail alimentaire sur éventaires et marchés",
    '47.82': "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés",
    '47.82Z': "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés",
    '47.89': "Autres commerces de détail sur éventaires et marchés",
    '47.89Z': "Autres commerces de détail sur éventaires et marchés",
    '47.9': "Commerce de détail hors magasin, éventaires ou marchés",
    '47.91': "Vente à distance",
    '47.91A': "Vente à distance sur catalogue général",
    '47.91B': "Vente à distance sur catalogue spécialisé",
    '47.99': "Autres commerces de détail hors magasin, éventaires ou marchés",
    '47.99A': "Vente à domicile",
    '47.99B': "Vente par automates et autres commerces de détail hors magasin, éventaires ou marchés n.c.a.",
    'SECTION H': "TRANSPORTS ET ENTREPOSAGE",
    '49': "Transports terrestres et transport par conduites",
    '49.1': "Transport ferroviaire interurbain de voyageurs",
    '49.10': "Transport ferroviaire interurbain de voyageurs",
    '49.10Z': "Transport ferroviaire interurbain de voyageurs",
    '49.2': "Transports ferroviaires de fret",
    '49.20': "Transports ferroviaires de fret",
    '49.20Z': "Transports ferroviaires de fret",
    '49.3': "Autres transports terrestres de voyageurs",
    '49.31': "Transports urbains et suburbains de voyageurs",
    '49.31Z': "Transports urbains et suburbains de voyageurs",
    '49.32': "Transports de voyageurs par taxis",
    '49.32Z': "Transports de voyageurs par taxis",
    '49.39': "Autres transports terrestres de voyageurs n.c.a.",
    '49.39A': "Transports routiers réguliers de voyageurs",
    '49.39B': "Autres transports routiers de voyageurs",
    '49.39C': "Téléphériques et remontées mécaniques",
    '49.4': "Transports routiers de fret et services de déménagement",
    '49.41': "Transports routiers de fret",
    '49.41A': "Transports routiers de fret interurbains",
    '49.41B': "Transports routiers de fret de proximité",
    '49.41C': "Location de camions avec chauffeur",
    '49.42': "Services de déménagement",
    '49.42Z': "Services de déménagement",
    '49.5': "Transports par conduites",
    '49.50': "Transports par conduites",
    '49.50Z': "Transports par conduites",
    '50': "Transports par eau",
    '50.1': "Transports maritimes et côtiers de passagers",
    '50.10': "Transports maritimes et côtiers de passagers",
    '50.10Z': "Transports maritimes et côtiers de passagers",
    '50.2': "Transports maritimes et côtiers de fret",
    '50.20': "Transports maritimes et côtiers de fret",
    '50.20Z': "Transports maritimes et côtiers de fret",
    '50.3': "Transports fluviaux de passagers",
    '50.30': "Transports fluviaux de passagers",
    '50.30Z': "Transports fluviaux de passagers",
    '50.4': "Transports fluviaux de fret",
    '50.40': "Transports fluviaux de fret",
    '50.40Z': "Transports fluviaux de fret",
    '51': "Transports aériens",
    '51.1': "Transports aériens de passagers",
    '51.10': "Transports aériens de passagers",
    '51.10Z': "Transports aériens de passagers",
    '51.2': "Transports aériens de fret et transports spatiaux",
    '51.21': "Transports aériens de fret",
    '51.21Z': "Transports aériens de fret",
    '51.22': "Transports spatiaux",
    '51.22Z': "Transports spatiaux",
    '52': "Entreposage et services auxiliaires des transports",
    '52.1': "Entreposage et stockage",
    '52.10': "Entreposage et stockage",
    '52.10A': "Entreposage et stockage frigorifique",
    '52.10B': "Entreposage et stockage non frigorifique",
    '52.2': "Services auxiliaires des transports",
    '52.21': "Services auxiliaires des transports terrestres",
    '52.21Z': "Services auxiliaires des transports terrestres",
    '52.22': "Services auxiliaires des transports par eau",
    '52.22Z': "Services auxiliaires des transports par eau",
    '52.23': "Services auxiliaires des transports aériens",
    '52.23Z': "Services auxiliaires des transports aériens",
    '52.24': "Manutention",
    '52.24A': "Manutention portuaire",
    '52.24B': "Manutention non portuaire",
    '52.29': "Autres services auxiliaires des transports",
    '52.29A': "Messagerie, fret express",
    '52.29B': "Affrètement et organisation des transports",
    '53': "Activités de poste et de courrier",
    '53.1': "Activités de poste dans le cadre d'une obligation de service universel",
    '53.10': "Activités de poste dans le cadre d'une obligation de service universel",
    '53.10Z': "Activités de poste dans le cadre d'une obligation de service universel",
    '53.2': "Autres activités de poste et de courrier",
    '53.20': "Autres activités de poste et de courrier",
    '53.20Z': "Autres activités de poste et de courrier",
    'SECTION I': "HÉBERGEMENT ET RESTAURATION",
    '55': "Hébergement",
    '55.1': "Hôtels et hébergement similaire",
    '55.10': "Hôtels et hébergement similaire",
    '55.10Z': "Hôtels et hébergement similaire",
    '55.2': "Hébergement touristique et autre hébergement de courte durée",
    '55.20': "Hébergement touristique et autre hébergement de courte durée",
    '55.20Z': "Hébergement touristique et autre hébergement de courte durée",
    '55.3': "Terrains de camping et parcs pour caravanes ou véhicules de loisirs",
    '55.30': "Terrains de camping et parcs pour caravanes ou véhicules de loisirs",
    '55.30Z': "Terrains de camping et parcs pour caravanes ou véhicules de loisirs",
    '55.9': "Autres hébergements",
    '55.90': "Autres hébergements",
    '55.90Z': "Autres hébergements",
    '56': "Restauration",
    '56.1': "Restaurants et services de restauration mobile",
    '56.10': "Restaurants et services de restauration mobile",
    '56.10A': "Restauration traditionnelle",
    '56.10B': "Cafétérias et autres libres-services",
    '56.10C': "Restauration de type rapide",
    '56.2': "Traiteurs et autres services de restauration",
    '56.21': "Services des traiteurs",
    '56.21Z': "Services des traiteurs",
    '56.29': "Autres services de restauration",
    '56.29A': "Restauration collective sous contrat",
    '56.29B': "Autres services de restauration n.c.a.",
    '56.3': "Débits de boissons",
    '56.30': "Débits de boissons",
    '56.30Z': "Débits de boissons",
    'SECTION J': "INFORMATION ET COMMUNICATION",
    '58': "Édition",
    '58.1': "Édition de livres et périodiques et autres activités d'édition",
    '58.11': "Édition de livres",
    '58.11Z': "Édition de livres",
    '58.12': "Édition de répertoires et de fichiers d'adresses",
    '58.12Z': "Édition de répertoires et de fichiers d'adresses",
    '58.13': "Édition de journaux",
    '58.13Z': "Édition de journaux",
    '58.14': "Édition de revues et périodiques",
    '58.14Z': "Édition de revues et périodiques",
    '58.19': "Autres activités d'édition",
    '58.19Z': "Autres activités d'édition",
    '58.2': "Édition de logiciels",
    '58.21': "Édition de jeux électroniques",
    '58.21Z': "Édition de jeux électroniques",
    '58.29': "Édition d'autres logiciels",
    '58.29A': "Édition de logiciels système et de réseau",
    '58.29B': "Edition de logiciels outils de développement et de langages",
    '58.29C': "Edition de logiciels applicatifs",
    '59': "Production de films cinématographiques, de vidéo et de programmes de télévision ; enregistrement sonore et édition musicale",
    '59.1': "Activités cinématographiques, vidéo et de télévision",
    '59.11': "Production de films cinématographiques, de vidéo et de programmes de télévision",
    '59.11A': "Production de films et de programmes pour la télévision",
    '59.11B': "Production de films institutionnels et publicitaires",
    '59.11C': "Production de films pour le cinéma",
    '59.12': "Post-production de films cinématographiques, de vidéo et de programmes de télévision",
    '59.12Z': "Post-production de films cinématographiques, de vidéo et de programmes de télévision",
    '59.13': "Distribution de films cinématographiques, de vidéo et de programmes de télévision",
    '59.13A': "Distribution de films cinématographiques",
    '59.13B': "Edition et distribution vidéo",
    '59.14': "Projection de films cinématographiques",
    '59.14Z': "Projection de films cinématographiques",
    '59.2': "Enregistrement sonore et édition musicale",
    '59.20': "Enregistrement sonore et édition musicale",
    '59.20Z': "Enregistrement sonore et édition musicale",
    '60': "Programmation et diffusion",
    '60.1': "Édition et diffusion de programmes radio",
    '60.10': "Édition et diffusion de programmes radio",
    '60.10Z': "Édition et diffusion de programmes radio",
    '60.2': "Programmation de télévision et télédiffusion",
    '60.20': "Programmation de télévision et télédiffusion",
    '60.20A': "Edition de chaînes généralistes",
    '60.20B': "Edition de chaînes thématiques",
    '61': "Télécommunications",
    '61.1': "Télécommunications filaires",
    '61.10': "Télécommunications filaires",
    '61.10Z': "Télécommunications filaires",
    '61.2': "Télécommunications sans fil",
    '61.20': "Télécommunications sans fil",
    '61.20Z': "Télécommunications sans fil",
    '61.3': "Télécommunications par satellite",
    '61.30': "Télécommunications par satellite",
    '61.30Z': "Télécommunications par satellite",
    '61.9': "Autres activités de télécommunication",
    '61.90': "Autres activités de télécommunication",
    '61.90Z': "Autres activités de télécommunication",
    '62': "Programmation, conseil et autres activités informatiques",
    '62.0': "Programmation, conseil et autres activités informatiques",
    '62.01': "Programmation informatique",
    '62.01Z': "Programmation informatique",
    '62.02': "Conseil informatique",
    '62.02A': "Conseil en systèmes et logiciels informatiques",
    '62.02B': "Tierce maintenance de systèmes et d’applications informatiques",
    '62.03': "Gestion d'installations informatiques",
    '62.03Z': "Gestion d'installations informatiques",
    '62.09': "Autres activités informatiques",
    '62.09Z': "Autres activités informatiques",
    '63': "Services d'information",
    '63.1': "Traitement de données, hébergement et activités connexes ; portails Internet",
    '63.11': "Traitement de données, hébergement et activités connexes",
    '63.11Z': "Traitement de données, hébergement et activités connexes",
    '63.12': "Portails Internet",
    '63.12Z': "Portails Internet",
    '63.9': "Autres services d'information",
    '63.91': "Activités des agences de presse",
    '63.91Z': "Activités des agences de presse",
    '63.99': "Autres services d'information n.c.a.",
    '63.99Z': "Autres services d'information n.c.a.",
    'SECTION K': "ACTIVITÉS FINANCIÈRES ET D'ASSURANCE",
    '64': "Activités des services financiers, hors assurance et caisses de retraite",
    '64.1': "Intermédiation monétaire",
    '64.11': "Activités de banque centrale",
    '64.11Z': "Activités de banque centrale",
    '64.19': "Autres intermédiations monétaires",
    '64.19Z': "Autres intermédiations monétaires",
    '64.2': "Activités des sociétés holding",
    '64.20': "Activités des sociétés holding",
    '64.20Z': "Activités des sociétés holding",
    '64.3': "Fonds de placement et entités financières similaires",
    '64.30': "Fonds de placement et entités financières similaires",
    '64.30Z': "Fonds de placement et entités financières similaires",
    '64.9': "Autres activités des services financiers, hors assurance et caisses de retraite",
    '64.91': "Crédit-bail",
    '64.91Z': "Crédit-bail",
    '64.92': "Autre distribution de crédit",
    '64.92Z': "Autre distribution de crédit",
    '64.99': "Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.",
    '64.99Z': "Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.",
    '65': "Assurance",
    '65.1': "Assurance",
    '65.11': "Assurance vie",
    '65.11Z': "Assurance vie",
    '65.12': "Autres assurances",
    '65.12Z': "Autres assurances",
    '65.2': "Réassurance",
    '65.20': "Réassurance",
    '65.20Z': "Réassurance",
    '65.3': "Caisses de retraite",
    '65.30': "Caisses de retraite",
    '65.30Z': "Caisses de retraite",
    '66': "Activités auxiliaires de services financiers et d'assurance",
    '66.1': "Activités auxiliaires de services financiers, hors assurance et caisses de retraite",
    '66.11': "Administration de marchés financiers",
    '66.11Z': "Administration de marchés financiers",
    '66.12': "Courtage de valeurs mobilières et de marchandises",
    '66.12Z': "Courtage de valeurs mobilières et de marchandises",
    '66.19': "Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite",
    '66.19A': "Supports juridiques de gestion de patrimoine mobilier",
    '66.19B': "Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, n.c.a.",
    '66.2': "Activités auxiliaires d'assurance et de caisses de retraite",
    '66.21': "Évaluation des risques et dommages",
    '66.21Z': "Évaluation des risques et dommages",
    '66.22': "Activités des agents et courtiers d'assurances",
    '66.22Z': "Activités des agents et courtiers d'assurances",
    '66.29': "Autres activités auxiliaires d'assurance et de caisses de retraite",
    '66.29Z': "Autres activités auxiliaires d'assurance et de caisses de retraite",
    '66.3': "Gestion de fonds",
    '66.30': "Gestion de fonds",
    '66.30Z': "Gestion de fonds",
    'SECTION L': "ACTIVITÉS IMMOBILIÈRES",
    '68': "Activités immobilières",
    '68.1': "Activités des marchands de biens immobiliers",
    '68.10': "Activités des marchands de biens immobiliers",
    '68.10Z': "Activités des marchands de biens immobiliers",
    '68.2': "Location et exploitation de biens immobiliers propres ou loués",
    '68.20': "Location et exploitation de biens immobiliers propres ou loués",
    '68.20A': "Location de logements",
    '68.20B': "Location de terrains et d'autres biens immobiliers",
    '68.3': "Activités immobilières pour compte de tiers",
    '68.31': "Agences immobilières",
    '68.31Z': "Agences immobilières",
    '68.32': "Administration de biens immobiliers",
    '68.32A': "Administration d'immeubles et autres biens immobiliers",
    '68.32B': "Supports juridiques de gestion de patrimoine immobilier",
    'SECTION M': "ACTIVITÉS SPÉCIALISÉES, SCIENTIFIQUES ET TECHNIQUES",
    '69': "Activités juridiques et comptables",
    '69.1': "Activités juridiques",
    '69.10': "Activités juridiques",
    '69.10Z': "Activités juridiques",
    '69.2': "Activités comptables",
    '69.20': "Activités comptables",
    '69.20Z': "Activités comptables",
    '70': "Activités des sièges sociaux ; conseil de gestion",
    '70.1': "Activités des sièges sociaux",
    '70.10': "Activités des sièges sociaux",
    '70.10Z': "Activités des sièges sociaux",
    '70.2': "Conseil de gestion",
    '70.21': "Conseil en relations publiques et communication",
    '70.21Z': "Conseil en relations publiques et communication",
    '70.22': "Conseil pour les affaires et autres conseils de gestion",
    '70.22Z': "Conseil pour les affaires et autres conseils de gestion",
    '71': "Activités d'architecture et d'ingénierie ; activités de contrôle et analyses techniques",
    '71.1': "Activités d'architecture et d'ingénierie",
    '71.11': "Activités d'architecture",
    '71.11Z': "Activités d'architecture",
    '71.12': "Activités d'ingénierie",
    '71.12A': "Activité des géomètres",
    '71.12B': "Ingénierie, études techniques",
    '71.2': "Activités de contrôle et analyses techniques",
    '71.20': "Activités de contrôle et analyses techniques",
    '71.20A': "Contrôle technique automobile",
    '71.20B': "Analyses, essais et inspections techniques",
    '72': "Recherche-développement scientifique",
    '72.1': "Recherche-développement en sciences physiques et naturelles",
    '72.11': "Recherche-développement en biotechnologie",
    '72.11Z': "Recherche-développement en biotechnologie",
    '72.19': "Recherche-développement en autres sciences physiques et naturelles",
    '72.19Z': "Recherche-développement en autres sciences physiques et naturelles",
    '72.2': "Recherche-développement en sciences humaines et sociales",
    '72.20': "Recherche-développement en sciences humaines et sociales",
    '72.20Z': "Recherche-développement en sciences humaines et sociales",
    '73': "Publicité et études de marché",
    '73.1': "Publicité",
    '73.11': "Activités des agences de publicité",
    '73.11Z': "Activités des agences de publicité",
    '73.12': "Régie publicitaire de médias",
    '73.12Z': "Régie publicitaire de médias",
    '73.2': "Études de marché et sondages",
    '73.20': "Études de marché et sondages",
    '73.20Z': "Études de marché et sondages",
    '74': "Autres activités spécialisées, scientifiques et techniques",
    '74.1': "Activités spécialisées de design",
    '74.10': "Activités spécialisées de design",
    '74.10Z': "Activités spécialisées de design",
    '74.2': "Activités photographiques",
    '74.20': "Activités photographiques",
    '74.20Z': "Activités photographiques",
    '74.3': "Traduction et interprétation",
    '74.30': "Traduction et interprétation",
    '74.30Z': "Traduction et interprétation",
    '74.9': "Autres activités spécialisées, scientifiques et techniques n.c.a.",
    '74.90': "Autres activités spécialisées, scientifiques et techniques n.c.a.",
    '74.90A': "Activité des économistes de la construction",
    '74.90B': "Activités spécialisées, scientifiques et techniques diverses",
    '75': "Activités vétérinaires",
    '75.0': "Activités vétérinaires",
    '75.00': "Activités vétérinaires",
    '75.00Z': "Activités vétérinaires",
    'SECTION N': "ACTIVITÉS DE SERVICES ADMINISTRATIFS ET DE SOUTIEN",
    '77': "Activités de location et location-bail",
    '77.1': "Location et location-bail de véhicules automobiles",
    '77.11': "Location et location-bail de voitures et de véhicules automobiles légers",
    '77.11A': "Location de courte durée de voitures et de véhicules automobiles légers",
    '77.11B': "Location de longue durée de voitures et de véhicules automobiles légers",
    '77.12': "Location et location-bail de camions",
    '77.12Z': "Location et location-bail de camions",
    '77.2': "Location et location-bail de biens personnels et domestiques",
    '77.21': "Location et location-bail d'articles de loisirs et de sport",
    '77.21Z': "Location et location-bail d'articles de loisirs et de sport",
    '77.22': "Location de vidéocassettes et disques vidéo",
    '77.22Z': "Location de vidéocassettes et disques vidéo",
    '77.29': "Location et location-bail d'autres biens personnels et domestiques",
    '77.29Z': "Location et location-bail d'autres biens personnels et domestiques",
    '77.3': "Location et location-bail d'autres machines, équipements et biens",
    '77.31': "Location et location-bail de machines et équipements agricoles",
    '77.31Z': "Location et location-bail de machines et équipements agricoles",
    '77.32': "Location et location-bail de machines et équipements pour la construction",
    '77.32Z': "Location et location-bail de machines et équipements pour la construction",
    '77.33': "Location et location-bail de machines de bureau et de matériel informatique",
    '77.33Z': "Location et location-bail de machines de bureau et de matériel informatique",
    '77.34': "Location et location-bail de matériels de transport par eau",
    '77.34Z': "Location et location-bail de matériels de transport par eau",
    '77.35': "Location et location-bail de matériels de transport aérien",
    '77.35Z': "Location et location-bail de matériels de transport aérien",
    '77.39': "Location et location-bail d'autres machines, équipements et biens matériels n.c.a.",
    '77.39Z': "Location et location-bail d'autres machines, équipements et biens matériels n.c.a.",
    '77.4': "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
    '77.40': "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
    '77.40Z': "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
    '78': "Activités liées à l'emploi",
    '78.1': "Activités des agences de placement de main-d'œuvre",
    '78.10': "Activités des agences de placement de main-d'œuvre",
    '78.10Z': "Activités des agences de placement de main-d'œuvre",
    '78.2': "Activités des agences de travail temporaire",
    '78.20': "Activités des agences de travail temporaire",
    '78.20Z': "Activités des agences de travail temporaire",
    '78.3': "Autre mise à disposition de ressources humaines",
    '78.30': "Autre mise à disposition de ressources humaines",
    '78.30Z': "Autre mise à disposition de ressources humaines",
    '79': "Activités des agences de voyage, voyagistes, services de réservation et activités connexes",
    '79.1': "Activités des agences de voyage et voyagistes",
    '79.11': "Activités des agences de voyage",
    '79.11Z': "Activités des agences de voyage",
    '79.12': "Activités des voyagistes",
    '79.12Z': "Activités des voyagistes",
    '79.9': "Autres services de réservation et activités connexes",
    '79.90': "Autres services de réservation et activités connexes",
    '79.90Z': "Autres services de réservation et activités connexes",
    '80': "Enquêtes et sécurité",
    '80.1': "Activités de sécurité privée",
    '80.10': "Activités de sécurité privée",
    '80.10Z': "Activités de sécurité privée",
    '80.2': "Activités liées aux systèmes de sécurité",
    '80.20': "Activités liées aux systèmes de sécurité",
    '80.20Z': "Activités liées aux systèmes de sécurité",
    '80.3': "Activités d'enquête",
    '80.30': "Activités d'enquête",
    '80.30Z': "Activités d'enquête",
    '81': "Services relatifs aux bâtiments et aménagement paysager",
    '81.1': "Activités combinées de soutien lié aux bâtiments",
    '81.10': "Activités combinées de soutien lié aux bâtiments",
    '81.10Z': "Activités combinées de soutien lié aux bâtiments",
    '81.2': "Activités de nettoyage",
    '81.21': "Nettoyage courant des bâtiments",
    '81.21Z': "Nettoyage courant des bâtiments",
    '81.22': "Autres activités de nettoyage des bâtiments et nettoyage industriel",
    '81.22Z': "Autres activités de nettoyage des bâtiments et nettoyage industriel",
    '81.29': "Autres activités de nettoyage",
    '81.29A': "Désinfection, désinsectisation, dératisation",
    '81.29B': "Autres activités de nettoyage n.c.a.",
    '81.3': "Services d'aménagement paysager",
    '81.30': "Services d'aménagement paysager",
    '81.30Z': "Services d'aménagement paysager",
    '82': "Activités administratives et autres activités de soutien aux entreprises",
    '82.1': "Activités administratives",
    '82.11': "Services administratifs combinés de bureau",
    '82.11Z': "Services administratifs combinés de bureau",
    '82.19': "Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau",
    '82.19Z': "Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau",
    '82.2': "Activités de centres d'appels",
    '82.20': "Activités de centres d'appels",
    '82.20Z': "Activités de centres d'appels",
    '82.3': "Organisation de salons professionnels et congrès",
    '82.30': "Organisation de salons professionnels et congrès",
    '82.30Z': "Organisation de foires, salons professionnels et congrès",
    '82.9': "Activités de soutien aux entreprises n.c.a.",
    '82.91': "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle",
    '82.91Z': "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle",
    '82.92': "Activités de conditionnement",
    '82.92Z': "Activités de conditionnement",
    '82.99': "Autres activités de soutien aux entreprises n.c.a.",
    '82.99Z': "Autres activités de soutien aux entreprises n.c.a.",
    'SECTION O': "ADMINISTRATION PUBLIQUE",
    '84': "Administration publique et défense ; sécurité sociale obligatoire",
    '84.1': "Administration générale, économique et sociale",
    '84.11': "Administration publique générale",
    '84.11Z': "Administration publique générale",
    '84.12': "Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale",
    '84.12Z': "Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale",
    '84.13': "Administration publique (tutelle) des activités économiques",
    '84.13Z': "Administration publique (tutelle) des activités économiques",
    '84.2': "Services de prérogative publique",
    '84.21': "Affaires étrangères",
    '84.21Z': "Affaires étrangères",
    '84.22': "Défense",
    '84.22Z': "Défense",
    '84.23': "Justice",
    '84.23Z': "Justice",
    '84.24': "Activités d’ordre public et de sécurité",
    '84.24Z': "Activités d’ordre public et de sécurité",
    '84.25': "Services du feu et de secours",
    '84.25Z': "Services du feu et de secours",
    '84.3': "Sécurité sociale obligatoire",
    '84.30': "Sécurité sociale obligatoire",
    '84.30A': "Activités générales de sécurité sociale",
    '84.30B': "Gestion des retraites complémentaires",
    '84.30C': "Distribution sociale de revenus",
    'SECTION P': "ENSEIGNEMENT",
    '85': "Enseignement",
    '85.1': "Enseignement pré-primaire",
    '85.10': "Enseignement pré-primaire",
    '85.10Z': "Enseignement pré-primaire",
    '85.2': "Enseignement primaire",
    '85.20': "Enseignement primaire",
    '85.20Z': "Enseignement primaire",
    '85.3': "Enseignement secondaire",
    '85.31': "Enseignement secondaire général",
    '85.31Z': "Enseignement secondaire général",
    '85.32': "Enseignement secondaire technique ou professionnel",
    '85.32Z': "Enseignement secondaire technique ou professionnel",
    '85.4': "Enseignement supérieur et post-secondaire non supérieur",
    '85.41': "Enseignement post-secondaire non supérieur",
    '85.41Z': "Enseignement post-secondaire non supérieur",
    '85.42': "Enseignement supérieur",
    '85.42Z': "Enseignement supérieur",
    '85.5': "Autres activités d'enseignement",
    '85.51': "Enseignement de disciplines sportives et d'activités de loisirs",
    '85.51Z': "Enseignement de disciplines sportives et d'activités de loisirs",
    '85.52': "Enseignement culturel",
    '85.52Z': "Enseignement culturel",
    '85.53': "Enseignement de la conduite",
    '85.53Z': "Enseignement de la conduite",
    '85.59': "Enseignements divers",
    '85.59A': "Formation continue d'adultes",
    '85.59B': "Autres enseignements",
    '85.6': "Activités de soutien à l'enseignement",
    '85.60': "Activités de soutien à l'enseignement",
    '85.60Z': "Activités de soutien à l'enseignement",
    'SECTION Q': "SANTÉ HUMAINE ET ACTION SOCIALE",
    '86': "Activités pour la santé humaine",
    '86.1': "Activités hospitalières",
    '86.10': "Activités hospitalières",
    '86.10Z': "Activités hospitalières",
    '86.2': "Activité des médecins et des dentistes",
    '86.21': "Activité des médecins généralistes",
    '86.21Z': "Activité des médecins généralistes",
    '86.22': "Activité des médecins spécialistes",
    '86.22A': "Activités de radiodiagnostic et de radiothérapie",
    '86.22B': "Activités chirurgicales",
    '86.22C': "Autres activités des médecins spécialistes",
    '86.23': "Pratique dentaire",
    '86.23Z': "Pratique dentaire",
    '86.9': "Autres activités pour la santé humaine",
    '86.90': "Autres activités pour la santé humaine",
    '86.90A': "Ambulances",
    '86.90B': "Laboratoires d'analyses médicales",
    '86.90C': "Centres de collecte et banques d'organes",
    '86.90D': "Activités des infirmiers et des sages-femmes",
    '86.90E': "Activités des professionnels de la rééducation, de l’appareillage et des pédicures-podologues",
    '86.90F': "Activités de santé humaine non classées ailleurs",
    '87': "Hébergement médico-social et social",
    '87.1': "Hébergement médicalisé",
    '87.10': "Hébergement médicalisé",
    '87.10A': "Hébergement médicalisé pour personnes âgées",
    '87.10B': "Hébergement médicalisé pour enfants handicapés",
    '87.10C': "Hébergement médicalisé pour adultes handicapés et autre hébergement médicalisé ",
    '87.2': "Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes",
    '87.20': "Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes",
    '87.20A': "Hébergement social pour handicapés mentaux et malades mentaux",
    '87.20B': "Hébergement social pour toxicomanes",
    '87.3': "Hébergement social pour personnes âgées ou handicapées physiques",
    '87.30': "Hébergement social pour personnes âgées ou handicapées physiques",
    '87.30A': "Hébergement social pour personnes âgées",
    '87.30B': "Hébergement social pour handicapés  physiques",
    '87.9': "Autres activités d’hébergement social",
    '87.90': "Autres activités d’hébergement social",
    '87.90A': "Hébergement social pour enfants en difficultés",
    '87.90B': "Hébergement social pour adultes et familles en difficultés et autre hébergement social",
    '88': "Action sociale sans hébergement",
    '88.1': "Action sociale sans hébergement pour personnes âgées et pour personnes handicapées",
    '88.10': "Action sociale sans hébergement pour personnes âgées et pour personnes handicapées",
    '88.10A': "Aide à domicile ",
    '88.10B': "Accueil ou accompagnement sans hébergement d’adultes handicapés ou de  personnes âgées",
    '88.10C': "Aide par le travail",
    '88.9': "Autre action sociale sans hébergement",
    '88.91': "Action sociale sans hébergement pour jeunes enfants",
    '88.91A': "Accueil de jeunes enfants",
    '88.91B': "Accueil ou accompagnement sans hébergement d’enfants handicapés",
    '88.99': "Autre action sociale sans hébergement n.c.a.",
    '88.99A': "Autre accueil ou accompagnement sans hébergement d’enfants  et d’adolescents",
    '88.99B': "Action sociale sans hébergement n.c.a.",
    'SECTION R': "ARTS, SPECTACLES ET ACTIVITÉS RÉCRÉATIVES",
    '90': "Activités créatives, artistiques et de spectacle",
    '90.0': "Activités créatives, artistiques et de spectacle",
    '90.01': "Arts du spectacle vivant",
    '90.01Z': "Arts du spectacle vivant",
    '90.02': "Activités de soutien au spectacle vivant",
    '90.02Z': "Activités de soutien au spectacle vivant",
    '90.03': "Création artistique",
    '90.03A': "Création artistique relevant des arts plastiques",
    '90.03B': "Autre création artistique",
    '90.04': "Gestion de salles de spectacles",
    '90.04Z': "Gestion de salles de spectacles",
    '91': "Bibliothèques, archives, musées et autres activités culturelles",
    '91.0': "Bibliothèques, archives, musées et autres activités culturelles",
    '91.01': "Gestion des bibliothèques et des archives",
    '91.01Z': "Gestion des bibliothèques et des archives",
    '91.02': "Gestion des musées",
    '91.02Z': "Gestion des musées",
    '91.03': "Gestion des sites et monuments historiques et des attractions touristiques similaires",
    '91.03Z': "Gestion des sites et monuments historiques et des attractions touristiques similaires",
    '91.04': "Gestion des jardins botaniques et zoologiques et des réserves naturelles",
    '91.04Z': "Gestion des jardins botaniques et zoologiques et des réserves naturelles",
    '92': "Organisation de jeux de hasard et d'argent",
    '92.0': "Organisation de jeux de hasard et d'argent",
    '92.00': "Organisation de jeux de hasard et d'argent",
    '92.00Z': "Organisation de jeux de hasard et d'argent",
    '93': "Activités sportives, récréatives et de loisirs",
    '93.1': "Activités liées au sport",
    '93.11': "Gestion d'installations sportives",
    '93.11Z': "Gestion d'installations sportives",
    '93.12': "Activités de clubs de sports",
    '93.12Z': "Activités de clubs de sports",
    '93.13': "Activités des centres de culture physique",
    '93.13Z': "Activités des centres de culture physique",
    '93.19': "Autres activités liées au sport",
    '93.19Z': "Autres activités liées au sport",
    '93.2': "Activités récréatives et de loisirs",
    '93.21': "Activités des parcs d'attractions et parcs à thèmes",
    '93.21Z': "Activités des parcs d'attractions et parcs à thèmes",
    '93.29': "Autres activités récréatives et de loisirs",
    '93.29Z': "Autres activités récréatives et de loisirs",
    'SECTION S': "AUTRES ACTIVITÉS DE SERVICES",
    '94': "Activités des organisations associatives",
    '94.1': "Activités des organisations économiques, patronales et professionnelles",
    '94.11': "Activités des organisations patronales et consulaires",
    '94.11Z': "Activités des organisations patronales et consulaires",
    '94.12': "Activités des organisations professionnelles",
    '94.12Z': "Activités des organisations professionnelles",
    '94.2': "Activités des syndicats de salariés",
    '94.20': "Activités des syndicats de salariés",
    '94.20Z': "Activités des syndicats de salariés",
    '94.9': "Activités des autres organisations associatives",
    '94.91': "Activités des organisations religieuses",
    '94.91Z': "Activités des organisations religieuses",
    '94.92': "Activités des organisations politiques",
    '94.92Z': "Activités des organisations politiques",
    '94.99': "Activités des organisations associatives n.c.a.",
    '94.99Z': "Autres organisations fonctionnant par adhésion volontaire",
    '95': "Réparation d'ordinateurs et de biens personnels et domestiques",
    '95.1': "Réparation d'ordinateurs et d'équipements de communication",
    '95.11': "Réparation d'ordinateurs et d'équipements périphériques",
    '95.11Z': "Réparation d'ordinateurs et d'équipements périphériques",
    '95.12': "Réparation d'équipements de communication",
    '95.12Z': "Réparation d'équipements de communication",
    '95.2': "Réparation de biens personnels et domestiques",
    '95.21': "Réparation de produits électroniques grand public",
    '95.21Z': "Réparation de produits électroniques grand public",
    '95.22': "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin",
    '95.22Z': "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin",
    '95.23': "Réparation de chaussures et d'articles en cuir",
    '95.23Z': "Réparation de chaussures et d'articles en cuir",
    '95.24': "Réparation de meubles et d'équipements du foyer",
    '95.24Z': "Réparation de meubles et d'équipements du foyer",
    '95.25': "Réparation d'articles d'horlogerie et de bijouterie",
    '95.25Z': "Réparation d'articles d'horlogerie et de bijouterie",
    '95.29': "Réparation d'autres biens personnels et domestiques",
    '95.29Z': "Réparation d'autres biens personnels et domestiques",
    '96': "Autres services personnels",
    '96.0': "Autres services personnels",
    '96.01': "Blanchisserie-teinturerie",
    '96.01A': "Blanchisserie-teinturerie de gros",
    '96.01B': "Blanchisserie-teinturerie de détail",
    '96.02': "Coiffure et soins de beauté",
    '96.02A': "Coiffure",
    '96.02B': "Soins de beauté",
    '96.03': "Services funéraires",
    '96.03Z': "Services funéraires",
    '96.04': "Entretien corporel",
    '96.04Z': "Entretien corporel",
    '96.09': "Autres services personnels n.c.a.",
    '96.09Z': "Autres services personnels n.c.a.",
    'SECTION T': "ACTIVITÉS DES MÉNAGES EN TANT QU'EMPLOYEURS ; ACTIVITÉS INDIFFÉRENCIÉES DES MÉNAGES EN TANT QUE PRODUCTEURS DE BIENS ET SERVICES POUR USAGE PROPRE",
    '97': "Activités des ménages en tant qu'employeurs de personnel domestique",
    '97.0': "Activités des ménages en tant qu'employeurs de personnel domestique",
    '97.00': "Activités des ménages en tant qu'employeurs de personnel domestique",
    '97.00Z': "Activités des ménages en tant qu'employeurs de personnel domestique",
    '98': "Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre",
    '98.1': "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre",
    '98.10': "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre",
    '98.10Z': "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre",
    '98.2': "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre",
    '98.20': "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre",
    '98.20Z': "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre",
    'SECTION U': "ACTIVITÉS EXTRA-TERRITORIALES",
    '99': "Activités des organisations et organismes extraterritoriaux",
    '99.0': "Activités des organisations et organismes extraterritoriaux",
    '99.00': "Activités des organisations et organismes extraterritoriaux",
    '99.00Z': "Activités des organisations et organismes extraterritoriaux",
}