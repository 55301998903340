import * as React from 'react';
import { useState, useEffect, Component } from 'react';
import { resolveBrowserLocale, useTranslate, withTranslate } from 'react-admin'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PostAdd from '@material-ui/icons/PostAdd';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { showNotification } from 'react-admin';
import { push as pushAction } from 'react-router-redux'
import { connect } from 'react-redux';

import dataProvider from '../dataProvider'


class Page extends Component {

    constructor(props) {
        super(props)

        this.state = {
            arr: [
                "Denominationsociales",
                "Emails",
                "Phones",
                "Websites",
                "Socials"
            ],
        }
    }

    async componentDidMount() {
        for (let i = 0; i < this.state.arr.length; i++) {
            const elem = this.state.arr[i]
            const res = await dataProvider.customs.getSources(elem.toLowerCase())
            const arr2 = [{ value: "All" }, ...res.ret]
            this.setState({
                [`Source${elem}`]: arr2
            })
            for (let j = 0; j < arr2.length; j++) {
                const elem2 = arr2[j]
                const res2 = await dataProvider.customs.count(elem.toLowerCase(), elem2.value !== "All" ? elem2.value : undefined)
                elem2.total = res2.ret.total
                this.setState({ [`Source${elem}`]: [...this.state[`Source${elem}`]] })
                const res3 = await dataProvider.customs.count(elem.toLowerCase(), elem2.value !== "All" ? elem2.value : undefined, true)
                elem2.total2 = res3.ret.total
                this.setState({ [`Source${elem}`]: [...this.state[`Source${elem}`]] })
            }
        }
        // this.state.arr.forEach(elem => {
        //     dataProvider.customs.getSources(elem.toLowerCase()).then(res => {
        //         this.setState({
        //             [`Source${elem}`]: [{ value: "All" }, ...res.ret]
        //         }, () => {
        //             this.state[`Source${elem}`].forEach(elem2 => {
        //                 dataProvider.customs.count(elem.toLowerCase(), elem2.value !== "All" ? elem2.value : undefined).then(res => {
        //                     elem2.total = res.ret.total
        //                     this.setState({ [`Source${elem}`]: [...this.state[`Source${elem}`]] })
        //                 })
        //                 dataProvider.customs.count(elem.toLowerCase(), elem2.value !== "All" ? elem2.value : undefined, true).then(res => {
        //                     elem2.total2 = res.ret.total
        //                     this.setState({ [`Source${elem}`]: [...this.state[`Source${elem}`]] })
        //                 })
        //             })
        //         })
        //     })
        // })
    }

    render() {

        return (
            <div>
                {
                    this.state.arr.map(elem => (
                        <div>
                            <h3>{elem}</h3>
                            <table>
                                {
                                    (this.state[`Source${elem}`] || []).map(elem2 => (
                                        <tr style={{ padding: "10px" }}>
                                            <td style={{ padding: "10px" }}>{elem2.value}</td>
                                            <td style={{ padding: "10px" }}>{elem2.total}</td>
                                            <td style={{ padding: "10px" }}>{elem2.total2}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default connect(null, {
    showNotification: showNotification,
    push: pushAction,
})(withTranslate(Page));
