
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    BlogpostInput,
    BlogpostField,
    ArrayInput,
    SelectInput,
    NumberInput,
    SimpleFormIterator,
    required,
    sanitizeListRestProps,
    BooleanInput,
    BooleanField,
    SaveButton,
    DeleteButton,
    Toolbar,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/PostAdd'
import ShortUrlField from '../Customs/ShortUrlField'
import MultipleField from '../Customs/MultipleField'
import MultipleInput from '../Customs/MultipleInput'
import DeleteAllButton from '../Customs/DeleteAllButton'
import ImportButton from '../Customs/ImportButton'
import MoveFormIterator from '../Customs/MoveFormIterator'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const BlogpostIcon = BookIcon

const Blogpost_Filter = (props) => (
    <Filter {...props}>
        <TextInput source="match_name" options={{ maxLength: 1024 }} />
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

export const BlogpostList = (props) => (
    <List {...props} filters={<Blogpost_Filter />} actions={<ListActions />} perPage={40}>
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.name : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="title" sortable={true} />
                    <TextField source="slugurl" sortable={true} />
                    <DateField source="createdAt" showTime={true} sortable={true} locales="fr-FR" />
                    <DateField source="updatedAt" showTime={true} sortable={true} locales="fr-FR" />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
)

export const BlogpostShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="slugurl" />
            <TextField source="weight" />
            <TextField source="type" />
            <ArrayField source="blocs">
                <Datagrid>
                    <TextField source="type" />
                    <TextField source="body" />
                </Datagrid>
            </ArrayField>
            <DateField source="createdAt" showTime={true} locales="fr-FR" />
            <DateField source="updatedAt" showTime={true} locales="fr-FR" />
        </SimpleShowLayout>
    </Show>
)

export const BlogpostEdit = (props) => (
    <Edit {...props} undoable={false} >
        <SimpleForm redirect="show">
            <TextInput source="title" validate={required()} />
            <TextInput source="slugurl" validate={required()} />
            <NumberInput source="weight" validate={required()} />
            <TextInput source="type" validate={required()} />
            <ArrayInput source="blocs">
                <MoveFormIterator>
                    <TextInput source="type" validate={required()} />
                    <TextInput source="body" validate={required()} />
                </MoveFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
)

export const BlogpostCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="title" validate={required()} />
            <TextInput source="slugurl" validate={required()} />
            <NumberInput source="weight" validate={required()} />
            <TextInput source="type" validate={required()} />
            <ArrayInput source="blocs">
                <MoveFormIterator>
                    <TextInput source="type" validate={required()} />
                    <TextInput source="body" validate={required()} />
                </MoveFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
)
