
import { Component } from 'react'
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts'
import { PieChart, Pie, Sector, Cell } from 'recharts'
import { AreaChart, Area } from 'recharts'
import { BarChart, Bar } from 'recharts'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import dataProvider from '../dataProvider'

const { fetchJson } = fetchUtils

const API_URL = process.env.REACT_APP_API_HOSTNAME

class Graph extends Component {

    constructor(props) {
        super(props)
        this.state = {
            json: null,
            ago: !localStorage.getItem('ago') ? 1 : parseInt(localStorage.getItem('ago')),
            start: new Date(Date.now() - 24 * 3600 * 1000),
            end: new Date(),
            types: [],
            users: [],
            type: undefined,
            user: undefined,
        }
        this.agos = [
            [null, "None"],
            [5 * 60, "5 min ago"],
            [15 * 60, "15 min ago"],
            [30 * 60, "30 min ago"],
            [3600, "1 hour ago"],
            [3 * 3600, "3 hour ago"],
            [6 * 3600, "6 hour ago"],
            [12 * 3600, "12 hour ago"],
            [24 * 3600, "24 hour ago"],
            [48 * 3600, "48 hour ago"],
            [72 * 3600, "72 hour ago"],
            [7 * 24 * 3600, "7 days ago"],
            [30 * 24 * 3600, "30 days ago"],
        ]
    }

    componentDidMount() {
        this.call()
    }

    call() {
        this.callUsers()
        this.callTypes()
        this.callJson()
    }

    callUsers() {
        const query = {}
        if (this.state.ago) {
            query.start = new Date(Date.now() - this.agos[this.state.ago][0] * 1000)
        } else {
            query.start = this.state.start
            query.end = this.state.end
        }
        dataProvider.customs.getRequestUsers(query.start, query.end).then((res) => {
            this.setState({ users: res.data })
        })
    }

    callTypes() {
        const query = {}
        if (this.state.ago) {
            query.start = new Date(Date.now() - this.agos[this.state.ago][0] * 1000)
        } else {
            query.start = this.state.start
            query.end = this.state.end
        }
        dataProvider.customs.getRequestTypes(query.start, query.end).then((res) => {
            this.setState({ types: res.data })
        })
    }

    callJson() {
        const query = {}
        if (this.state.ago) {
            query.start = new Date(Date.now() - this.agos[this.state.ago][0] * 1000)
        } else {
            query.start = this.state.start
            query.end = this.state.end
        }
        dataProvider.customs.graph(query.start, query.end, this.state.user, this.state.type).then((res) => {
            this.setState({ json: res })
        })
    }

    render() {
        const formatXDate = (d) => {
            d = new Date(d)
            return `${d.getDate()},${d.getHours()}:${d.getMinutes()}`
        }
        return (
            <div>
                <div>
                    <FormControl variant="filled" style={{ margin: "20px", minWidth: "200px" }}>
                        <InputLabel>Ago</InputLabel>
                        <Select
                            value={this.state.ago}
                            onChange={(event) => {
                                localStorage.setItem('ago', event.target.value)
                                this.setState({ ago: event.target.value, json: null }, () => this.call())
                            }}
                        >
                            {
                                this.agos.map((elem, index) => <MenuItem value={index}>{elem[1]}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    {
                        this.state.ago === 0 && (
                            <TextField
                                style={{ margin: "20px" }}
                                id="datetime-local"
                                label="Start"
                                type="datetime-local"
                                value={new Date(this.state.start.getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().substring(0, '2021-05-16T19:53'.length)}
                                onChange={(event) => {
                                    this.setState({ start: new Date(new Date(event.target.value).getTime()), json: null }, () => this.call())
                                }}
                                // defaultValue="2017-05-24T10:30"
                                // className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )
                    }
                    {
                        this.state.ago === 0 && (
                            <TextField
                                style={{ margin: "20px" }}
                                id="datetime-local"
                                label="End"
                                type="datetime-local"
                                value={new Date(this.state.end.getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().substring(0, '2021-05-16T19:53'.length)}
                                onChange={(event) => {
                                    this.setState({ end: new Date(new Date(event.target.value).getTime()), json: null }, () => this.call())
                                }}
                                // defaultValue="2017-05-24T10:30"
                                // className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )
                    }
                </div>
                <div>
                    <FormControl variant="filled" style={{ margin: "20px", minWidth: "200px" }}>
                        <InputLabel>User</InputLabel>
                        <Select
                            value={this.state.user}
                            onChange={(event) => {
                                this.setState({ user: event.target.value, json: null }, () => this.call())
                            }}
                        >
                            <MenuItem value={undefined}>None</MenuItem>
                            {
                                this.state.users.map((elem) => <MenuItem value={elem.value}>{elem.email}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="filled" style={{ margin: "20px", minWidth: "200px" }}>
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={this.state.type}
                            onChange={(event) => {
                                this.setState({ type: event.target.value, json: null }, () => this.call())
                            }}
                        >
                            <MenuItem value={undefined}>None</MenuItem>
                            {
                                this.state.types.map((elem) => <MenuItem value={elem.value}>{elem.value}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </div>
                {
                    this.state.json && (
                        <div>
                            {
                                this.state.json.user && (
                                    <div style={{ margin: "auto" }}>
                                        <div style={{ fontWeight: "bold", marginTop: "10px", marginBottom: "5px", width: "100%", textAlign: "center" }}>All</div>
                                        <div style={{ width: "100%", textAlign: "center" }}>{this.state.json.user.current} requests</div>
                                        <div style={{ width: "100%", textAlign: "center" }}>MAX: {this.state.json.user.total}</div>
                                        <PieChart width={200} height={250} style={{ margin: "auto" }}>
                                            <Pie
                                                dataKey="value"
                                                startAngle={360}
                                                endAngle={0}
                                                data={[
                                                    { value: this.state.json.user.current, fill: '#82ca9d', name: "Used" },
                                                    { value: this.state.json.user.total - this.state.json.user.current, name: "Not used" },
                                                ]}
                                                cx="50%"
                                                cy="50%"
                                                outerRadius={80}
                                            />
                                            <Legend />
                                        </PieChart>
                                    </div>
                                )
                            }
                            <div style={{ margin: "auto", marginTop: "40px", marginBottom: "40px" }}>
                                <AreaChart
                                    width={1000}
                                    height={350}
                                    data={this.state.json.requests.map(elem => (
                                        {
                                            "name": formatXDate(elem.date),
                                            "total": elem.total,
                                        }
                                    ))}
                                    style={{ margin: "auto" }}
                                >
                                    <defs>
                                        <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Area type="monotone" dataKey="total" stroke="#82ca9d" fill="url(#color)" />
                                </AreaChart>
                            </div>
                        </div>
                    )

                }
            </div>
        )
    }
}

export default Graph
