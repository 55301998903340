
import { Component } from 'react'
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'
// import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts'
// import { PieChart, Pie, Sector, Cell } from 'recharts'
// import { AreaChart, Area } from 'recharts'
// import { BarChart, Bar } from 'recharts'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem'
import Chart from "react-apexcharts";

import dataProvider from '../dataProvider'
import authProvider from '../authProvider'

const { fetchJson } = fetchUtils


const API_URL = process.env.REACT_APP_API_HOSTNAME

class Graph extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        authProvider.checkAuth().then(() => {
            this.call()
        }).catch((err) => {
            console.error(err)
            authProvider.logout().then(() => window.location = '/#/login')
        })
    }

    call() {
        dataProvider.customs.valueserp_account().then((res) => {
            dataProvider.customs.getAlert("valueserp").then((res2) => {
                this.setState({ valueserp: res.data, alert: res2.enabled })
            })
        })
    }

    alert() {
        this.setState({ alert: !this.state.alert }, () => {
            dataProvider.customs.alert("valueserp", this.state.alert).then(() => {
                this.call()
            })
        })
    }

    renderAlert() {
        if (!this.state.valueserp)
            return <></>
        return (
            <div style={{ marginBottom: "10px", display: "flex" }}>
                <div>
                    <Card style={{ padding: "10px", display: "flex" }} >
                        <h5>{"Alert: "}</h5>
                        <div style={{ paddingTop: "10px", marginLeft: "20px" }}>
                            <Switch label={"Alert"} checked={this.state.alert} onChange={() => this.alert()} />
                        </div>
                    </Card>
                </div>
            </div>
        )
    }

    renderValueserp() {
        if (!this.state.valueserp)
            return <></>
        const valueserp = this.state.valueserp

        const colors = [
            "#CC0000",
            "#CC7C00",
            "#CCCC00",
            "#00CC00",
            "#0000CC",
            "#2E2C5C",
            "#8C00CC",

            "#DD0000",
            "#DD7C00",
            "#DDDD00",
            "#00DD00",
            "#0000DD",
            "#2E2C5C",
            "#8C00DD",

            "#EE0000",
            "#EE7C00",
            "#EEEE00",
            "#00EE00",
            "#0000EE",
            "#2E2C5C",
            "#8C00EE",
        ]

        const commonOptionsTimeline = {
            options: {

                colors,
                chart: {
                    fontFamily: 'DM Sans',
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        type: "vertical",
                        shadeIntensity: 0.5,
                        inverseColors: false,
                        opacityFrom: .8,
                        opacityTo: .2,
                        stops: [0, 50, 100],
                        colorStops: []
                    }
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                yaxis: {
                    labels: {
                        offsetY: 0,
                        minWidth: 20,
                        maxWidth: 20
                    },
                },
                xaxis: {
                    type: 'number',
                    labels: {
                        minHeight: 20,
                        maxHeight: 20
                    },
                },
            },
        }

        const timeline_chart0 = {
            ...commonOptionsTimeline,
        }

        return (
            <div>
                <Card style={{ padding: "10px" }} >
                    <div style={{ display: 'flex' }}>
                        <div>Crédits Restants:</div>
                        <div style={{ marginLeft: "20px" }}>{valueserp.account_info.topup_credits_remaining + valueserp.account_info.monthly_credits_remaining }</div>
                    </div>
                </Card>
                {
                    valueserp.account_info.usage_history.map(e => {
                        const options = JSON.parse(JSON.stringify(timeline_chart0.options))
                        options.xaxis.categories = Object.keys(e.credits_total_per_day)

                        return (
                            <div>
                                <Card style={{ padding: "10px", marginTop: "20px" }} >
                                    <h4 style={{ margin: "0px" }} >{e.month}</h4>
                                    <Chart className="custom-chart" options={options} series={[{ name: "Usage", data: Object.values(e.credits_total_per_day) }]} type="area" height="265" />
                                </Card>
                            </div>
                        )
                    })
                }
            </div >
        )
    }

    render() {
        return (
            <div style={{ marginTop: "20px" }} >
                {this.renderAlert()}
                {this.renderValueserp()}
            </div>
        )
    }
}

export default Graph
