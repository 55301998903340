
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    FunctionField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    RichTextField,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    SelectArrayInput,
    ReferenceField,
    ImageField,
    BooleanField,
    BooleanInput,
    SearchInput,
    DateTimeInput,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import Icon from '@material-ui/icons/Book';
import ImportButton from '../Customs/ImportButton';

export const ScraperWebshareProxyIcon = Icon

const ListActions = (props) => {
    console.log(props)
    return (
        <TopToolbar>
            <CreateButton {...props} />
            <ImportButton {...props} resource="ScraperWebshareProxy" parser={(filecontent) => {
                return filecontent.split('\r').join('').split('\n').map(e => e.split(':')).map(e => ({
                    host: e[0],
                    port: e[1],
                })).filter(e => !!e.host)
            }} />
        </TopToolbar>
    );
}

export const ScraperWebshareProxyList = (props) => (
    <List {...props} empty={false} actions={<ListActions />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 50, 100, 200]} />}>
        <Datagrid>
            <TextField source="id" sortable={true} />
            <FunctionField label="proxy" render={(record) => `${record.host}:${record.port}${record.username ? `@${record.username}:${record.password}` : ''}`} />
            <DateField source="updatedAt" showTime={true} sortable={true} />
            {/* <EditButton />
            <ShowButton /> */}
        </Datagrid>
    </List>
)

export const ScraperWebshareProxyShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="host" />
            <TextField source="port" />
            <DateField source="createdAt" showTime={true} />
            {/* <DateField source="updatedAt" showTime={true} /> */}
        </SimpleShowLayout>
    </Show>
)

// export const ScraperWebshareProxyEdit = (props) => (
//     <Edit {...props}>
//         <SimpleForm redirect="show">
//             <BooleanInput source="status" />
//         </SimpleForm>
//     </Edit>
// )

export const ScraperWebshareProxyCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="host" />
            <TextInput source="port" />
        </SimpleForm>
    </Create>
)
