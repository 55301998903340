import * as React from 'react';
import { useState, useEffect, Component } from 'react';
import { resolveBrowserLocale, useTranslate, withTranslate } from 'react-admin'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PostAdd from '@material-ui/icons/PostAdd';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { showNotification } from 'react-admin';
import { push as pushAction } from 'react-router-redux'
import { connect } from 'react-redux';

import dataProvider from '../dataProvider'


class Page extends Component {

    constructor(props) {
        super(props)

        this.state = {
            subject: '',
            body: '',
            sent: false
        }
    }

    componentDidMount() {

    }

    render() {

        return (
            <div>
                <div
                    style={{
                        margin: "10px"
                    }}
                >
                    <TextField
                        value={this.state.subject}
                        label="Subject"
                        variant="outlined"
                        style={{
                            width: "100%"
                        }}
                        onChange={(e) => {
                            this.setState({ subject: e.target.value })
                        }}
                    />
                </div>
                <div
                    style={{
                        margin: "10px"
                    }}
                >
                    <TextField
                        value={this.state.body}
                        label="Body"
                        variant="outlined"
                        multiline
                        rows={8}
                        style={{
                            width: "100%"
                        }}
                        onChange={(e) => {
                            this.setState({ body: e.target.value })
                        }}
                    />
                </div>
                <div
                    style={{
                        margin: "10px"
                    }}
                >
                    {
                        !this.state.sent && (
                            <Button variant="contained" color="primary" onClick={() => {
                                const subject = this.state.subject
                                const body = this.state.body
                                this.setState({ sent: true })
                                dataProvider.customs.mail.send(subject, body).then(() => this.props.showNotification("Mail Sent"))
                            }}>
                                Send
                            </Button>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default connect(null, {
    showNotification: showNotification,
    push: pushAction,
})(withTranslate(Page));

/*
Bonjour,

Ceci est un test

Bien à vous
Anthony
*/

