
import { resolveBrowserLocale } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import frenchMessages from 'ra-language-french'
import englishMessages from 'ra-language-english'

export const messages = {
    fr: {
        ra: {
            ...(frenchMessages.ra),
            customs: {
                dashboard_title: "Tableau de bord",
                dashboard_welcome: "Bienvenue sur le tableau de bord",
                change_lang: "Changer de Langue",
                delete_confirmation: "Etes vous sur de tout supprimer ?",
                deleting: "En cours de suppression...",
                import: "Importer",
                submit: "Envoyer",
                close: "Fermer",
                cancel: "Annuler",
                choose_a_file: "Choisissez un fichier",
                change_password: "Changer le mot de passe",
                old_password: "Mot de passe actuel",
                new_password: "Nouveau mot de passe",
                repeat_password: "Répéter le mot de passe",
                strong_password: "Mot de passe parfait",
                minimum_8_characters: '8 caractères minimum',
                missing_special_character: 'Caractère spécial manquant [!@#$%^&*]',
                missing_numeric_character: 'Caractère numerique manquant',
                missing_capital_character: 'Caractère majuscule manquant',
                missing_alphabetic_character: 'Caractère alphabetique manquant',
                same_passwords: 'Mot de passes identiques',
                different_passwords: 'Mot de passes différents',
                calendar: 'Calendrier',
                mail_sms: 'Mail/Sms',
                month: 'Mois',
                week: 'Semaine',
                attendance_sheet: 'Feuille de présence',
                send_test: 'Envoie de tests email/sms',
                send_mail_1h: 'Envoie Mail : 1H',
                send_mail_24h: 'Envoie Mail : 24H',
                send_mail_after_course: 'Envoie Mail : Après le cours',
                send_mail_planning: 'Envoie Mail : Emploie du temps',
                send_mail_reminder_signature: 'Envoie Mail : Rappel de signature',
                send_mail_student_registration: 'Envoie Mail : Inscription d\'un élève',
                send_sms_1h: 'Envoie Sms : 1H',
                send_sms_24h: 'Envoie Sms : 24H',
                send_sms_after_course: 'Envoie Sms : Après le cours',
                send_sms_planning: 'Envoie Sms : Emploie du temps',
                send_sms_reminder_signature: 'Envoie Sms : Rappel de signature',
                send_sms_student_registration: 'Envoie Sms : Inscription d\'un élève',
                email: 'Email',
                sms: 'Sms',
                phone: 'Téléphone',
                classroom_url: 'Classe en ligne',
                start: 'Début',
                date: 'Date',
                sms_successfully_sent: 'Envoie sms réussi',
                email_successfully_sent: 'Envoi de l\'email réussi',
                pwd_succesfully_changed: 'Changement de mot de passe réussi',
                error_occured: 'Une erreur est survenue',
                student_firstname : "Prénom de l'étudiant",
                student_lastname : "Nom de l'étudiant",
                student_signature : "Signature de l'étudiant",
                next_course : "Prochain cours",
                professor_firstname : "Prénom du professeur",
                professor_lastname : "Nom du professeur",
                professor_signature : "Signature du professeur",
                course_name : 'Nom du cours',
                course_start : 'Début du cours',
                professor_or_student_firstname : "Prénom du professeur ou de l'étudiant",
                data_50_mo_max : "Fichier (50 Mo Max)",
                session_progress: "Progression",
                session_tracking: "Suivi",
                session_done_at: "Fait à",
                session_downloaded: "Téléchargé",
                session_uploaded: "OK",
                menu_Inpi : "Inpi",
                menu_company : "Sociétés",
                menu_users : "Utilisateurs",
                menu_blogs : "Blogs",
                menu_jobs : "Jobs",
                menu_scrap : "Scrap",
                from: "De",
                to: "A",
                name : "Nom",
                signature : "Signature",
                chat_with : "Chat avec",
                download : "Télécharger",
                missing_classroom: 'Pas de classe en ligne',
                sign: "Signer",
                write_your_signature: 'Signez dans le petit encadré en dessous:',
                by_signing : 'En signant, je confirme que j\'étais présent au cours',
                hello : 'Bonjour',
                welcome: 'Bienvenue',
                learn: 'Lancer le cours',
                start_run: 'Lancer',
                resume : 'Reprendre',
                upload_doc_50_mo: 'Envoyer document (50 Mo Max)',
                upload_doc: 'Envoyer document',
                uploaded_doc: 'Document envoyé',
                score: "Score",
                multiple_responses: "Plusieurs réponses possibles",
                simple_response: "Une seule réponse possible",
                next: "Suivant",
                sign_to_download_certificate: "Signer pour télécharger le certificat",
                download_certificate: "Télécharger le certificat",
                download_end_statement: "Télécharger la déclaration de fin",
                professor: "Professeur",
                student: "Eleve",
                bookings: "Réservations",
                unavailable_slots: "Crénaux indisponible",
                static_slot: "Crénaux non modifiable",
                save: "Sauvegarder",
                success_sent: "Envoie réussi",
                get: "Récupérer",
                validate: "Valider",
                count : "Compter"
            },
        },
        resources: {
            Company: {
                name: "Société |||| Sociétés",
                fields: {
                    status: "Etat",
                    late: "Retard en mois",
                    missing: "Bilans manquants",
                    last_bilan: "Dernier Bilan",
                    updatedAt: 'Mise à jour',
                    createdAt: 'Création'
                },
            },
            User: {
                name: "Utilisateur |||| Utilisateurs",
                fields: {
                    updatedAt: 'Mise à jour',
                    createdAt: 'Création'
                },
            },
            Preuser: {
                name: "Pré-Utilisateur |||| Pré-Utilisateurs",
                fields: {
                    updatedAt: 'Mise à jour',
                    createdAt: 'Création'
                },
            }
        }
    },
    en: {
        ra: {
            ...(englishMessages.ra),
            customs: {
                dashboard_title: "Dashboard",
                dashboard_welcome: "Welcome on the dashboard",
                change_lang: "Change Language",
                delete_confirmation: "Are you sure to delete all ?",
                deleting: "Deleting...",
                import: "Import",
                submit: "Submit",
                close: "Close",
                cancel: "Cancel",
                choose_a_file: "Choose a file",
                change_password: "Change password",
                old_password: "Current password",
                new_password: "New password",
                repeat_password: "Repeat password",
                strong_password: "Strong password",
                minimum_8_characters: 'Minimum 8 characters',
                missing_special_character: 'Missing special character [!@#$%^&*]',
                missing_numeric_character: 'Missing numeric character',
                missing_capital_character: 'Missing capital character',
                missing_alphabetic_character: 'Missing alphabetic character',
                same_passwords: 'Same passwords',
                different_passwords: 'Different passwords',
                calendar: 'Calendar',
                mail_sms: 'Mail/Sms',
                month: 'Month',
                week: 'Week',
                attendance_sheet: 'Attendance Sheet',
                send_test: 'Send test email/sms',
                send_mail_1h: 'Send Mail : 1H',
                send_mail_24h: 'Send Mail : 24H',
                send_mail_after_course: 'Send Mail : After Course',
                send_mail_planning: 'Send Mail : Planning',
                send_mail_reminder_signature: 'Send Mail : Reminder Signature',
                send_mail_student_registration: 'Send Mail : Student Registration',
                send_sms_1h: 'Send Sms : 1H',
                send_sms_24h: 'Send Sms : 24H',
                send_sms_after_course: 'Send Sms : After Course',
                send_sms_planning: 'Send Sms : Planning',
                send_sms_reminder_signature: 'Send Sms : Reminder Signature',
                send_sms_student_registration: 'Send Sms : Student Registration',
                email: 'Email',
                phone: 'Phone',
                classroom_url: 'Classroom Url',
                start: 'Start',
                date: 'Date',
                sms_successfully_sent: 'Sms successfully sent',
                email_successfully_sent: 'Email successfully sent',
                pwd_succesfully_changed: 'Password successfully changed',
                error_occurred: 'An error has occurred',
                student_firstname : "Student Firstname",
                student_lastname : "Student Lastname",
                student_signature : "Student Signature",
                next_course : "Next course",
                professor_firstname : "Professeur firstname",
                professor_lastname : "Professeur lastname",
                professor_signature : "Professeur signature",
                course_name : 'Course name',
                course_start : 'Course start',
                professor_or_student_firstname : "Professor or student firstname",
                data_50_mo_max : "Filedata (50 Mo Max)",
                session_progress: "Progress",
                session_tracking: "Tracking",
                session_done_at: "Done At",
                session_downloaded: "Downloaded",
                session_uploaded: "Uploaded",
                menu_users : "Users",
                menu_meeting : "Meeting",
                menu_elearning : "Elearning",
                menu_Inpi : "Inpi",
                menu_blogs : "Blogs",
                menu_jobs : "Jobs",
                from: "From",
                to: "To",
                name : "Name",
                signature : "Signature",
                chat_with : "Chat with",
                download: "Download",
                missing_classroom: 'Missing classroom',
                sign: "Sign",
                write_your_signature: 'Please write your signature in the box below :',
                by_signing : 'By signing, I confirm I am present in the course :',
                hello : 'Hello',
                welcome: 'Welcome',
                learn: 'Learn',
                upload_doc_50_mo: 'Upload document (50 Mo Max)',
                upload_doc: 'Upload document',
                uploaded_doc: 'Uploaded document',
                score: "Score",
                multiple_responses: "Multiple Responses",
                simple_response: "Simple response",
                next: "Next",
                sign_to_download_certificate: "Sign to download Certificate",
                download_certificate: "Download Certificate",
                download_end_statement: "Download End Statement",
                professor: "Professor",
                student: "Student",
                bookings: "Bookings",
                unavailable_slots: "Unavailable Slots",
                static_slot: "Slot not movable",
                save: "Save",
                success_sent: "Success sent",
                get : "Get",
                validate: "Validate",
                count : "Count"
            },
        },
        resources: {

        }

    }
};

export const i18nProvider = polyglotI18nProvider(
    locale => {
        // console.log(messages)
        return messages.fr
        return messages[locale] ? messages[locale] : messages.en
    },
    localStorage.getItem('locale') || resolveBrowserLocale()
);

