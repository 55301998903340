import * as React from 'react';
import { useState, useEffect, Component } from 'react';
import { resolveBrowserLocale, useTranslate, withTranslate } from 'react-admin'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PostAdd from '@material-ui/icons/PostAdd';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { showNotification } from 'react-admin';
import { push as pushAction } from 'react-router-redux'
import { connect } from 'react-redux';
import { VncScreen } from 'react-vnc';

import dataProvider from '../dataProvider'


class Page extends Component {

    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {

    }

    handleConnect() {
        console.log("connected")
    };

    handleDisconnect = () => {
        console.log("disconnected")
    };

    handleError = (err) => {
        console.log(`Error: ${err.message}`)
    };

    render() {

        return (
            <div>
                <VncScreen
                    url='wss://162.19.230.6:1'
                    credentials={{ password: '12345678' }}
                    scaleViewport
                    background="#000000"
                    style={{
                        width: '75vw',
                        height: '75vh',
                    }}
                    debug={true}
                    onConnect={() => this.handleConnect()}
                    onDisconnect={() => this.handleDisconnect()}
                    onError={(err) => this.handleError(err)}
                />
            </div>
        )
    }
}

export default connect(null, {
    showNotification: showNotification,
    push: pushAction,
})(withTranslate(Page));

/*
Bonjour,

Ceci est un test

Bien à vous
Anthony
*/

