
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    RichTextField,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    SelectArrayInput,
    ReferenceField,
    ImageField,
    BooleanField,
    BooleanInput,
    SearchInput,
    DateTimeInput,
    FunctionField,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import Icon from '@material-ui/icons/Book';

import JsonField from '../Customs/JsonField';

export const ScrappedResourceIcon = Icon

const filterArray = [
    <TextInput source="key" alwaysOn />,
    <TextInput source="tag" />,
    <TextInput source="status" />,
]

const FilterList = (props) => (
    <Filter {...props}>
        {filterArray}
    </Filter>
)

const ListActions = (props) => {
    console.log(props)
    return (
        <TopToolbar>
            <FilterButton filters={filterArray} />
        </TopToolbar>
    );
}

const nonOccurence = (str) => {
    const array = [
        "societe",
        "pappers",
        "abosociete",
        "lefigaro",
        "trouver-ouvert.fr",
        "kompass",
        "francebleu",
        "annuaire",
        "verif.com",
        "societe.com",
        "pagesjaunes",
        "societeinfo.com",
        "manageo",
        "linkedin",
        "infonet",
        "youtube",
        "facebook",
        "118000",
        "123pages",
        "doctrine",
        "mappy",
        "amazon",
        "data.gouv.fr",
        "insee.fr",
        "wikipedia",
        "telephone.city",
        "118712",
        "doctolib",
    ]
    return array.filter(e => str.includes(e)).length === 0
}

export const ScrappedResourceList = (props) => (
    <List {...props} filters={<FilterList />} actions={<ListActions />} perPage={10} pagination={<Pagination rowsPerPageOptions={[5, 10, 20, 50]} />}>
        <Datagrid>
            <TextField source="id" sortable={true} />
            <TextField source="key" sortable={false} />
            <TextField source="tag" sortable={false} />
            <TextField source="status" sortable={false} />

            {/* <FunctionField label={"name"} render={(record) => (<div>{record.input?.fiche?.denominationUniteLegale || `${record.input?.fiche?.prenom1UniteLegale} ${record.input?.fiche?.nomUniteLegale}`}</div>)} />
            <FunctionField label={"siren"} render={(record) => (<div>{record.input?.fiche?.siren}</div>)} /> */}

            {/* <FunctionField label={"input"} render={(record) => ( <ReactJson src={record.input} collapsed={true} /> )} />
            <FunctionField label={"output"} render={(record) => ( <ReactJson src={record.output} collapsed={true} /> )} /> */}


            {/* <FunctionField label={"organic_result"} render={(record) => (record.output?.organic_results?.filter(e => nonOccurence(e.link)).map(e => <div>{e.link}</div>))} /> */}
            {/* <FunctionField label={"linkedin"} render={(record) => (record.output?.organic_results?.filter(e => e.link.includes('linkedin')).map(e => <div>{e.link}</div>))} /> */}

            {/* <FunctionField label={"knowledge_graph"} render={(record) => ( <ReactJson src={record.output?.knowledge_graph} /> )} />0
            <FunctionField label={"type"} render={(record) => ( <div>{record.output?.knowledge_graph?.type}</div> )} />
            <FunctionField label={"description"} render={(record) => ( <div>{record.output?.knowledge_graph?.description}</div> )} />
            <FunctionField label={"rating"} render={(record) => ( <div>{record.output?.knowledge_graph?.rating}</div> )} />
            <FunctionField label={"reviews"} render={(record) => ( <div>{record.output?.knowledge_graph?.reviews}</div> )} />
            <FunctionField label={"address"} render={(record) => ( <div>{record.output?.knowledge_graph?.address}</div> )} />
            <FunctionField label={"hours"} render={(record) => ( <div>{JSON.stringify(record.output?.knowledge_graph?.hours)}</div> )} />
            <FunctionField label={"website"} render={(record) => (<div>{record.output?.knowledge_graph?.website?.substring(0, 66) || "none"}</div>)} />
            <FunctionField label={"known_attributes"} render={(record) => ( <ReactJson src={record.output?.knowledge_graph?.known_attributes} /> )} />
            <FunctionField label={"téléphones"} render={(record) => (record.output?.knowledge_graph?.known_attributes?.
                filter(e => ["Appeler", "kc:/local:alt phone", "Téléphone"].includes(e.name))
                .map(e => (<div>{(e.value || "").split('Téléphone').join('').split(':').join('').trim()}</div>)))} />
            <FunctionField label={"profils"} render={(record) => (
                Array.from(new Set([
                    ...(record.output?.knowledge_graph?.known_attributes?.
                        filter(e => ["Profils"].includes(e.name) && e.value.startsWith('http'))
                        .map(e => e.value) || []),
                    ...(record.output?.knowledge_graph?.profils?.
                        map(e => e.link) || [])
                ])).map(e => (<div>{e}</div>))
            )} /> */}

            <DateField source="updatedAt" showTime={true} sortable={true} />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List >
)

export const ScrappedResourceShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="key" />
            <TextField source="tag" />
            <TextField source="status" />
            <JsonField
                source="input"
                reactJsonOptions={{
                    name: null,
                    collapsed: true,
                    enableClipboard: true,
                    displayDataTypes: false,
                }}
            />
            <JsonField
                source="output"
                reactJsonOptions={{
                    name: null,
                    collapsed: true,
                    enableClipboard: true,
                    displayDataTypes: false,
                }}
            />
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)

export const ScrappedResourceEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="show">
            <TextInput source="status" />
        </SimpleForm>
    </Edit>
)
