
import { Component } from 'react'
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'
// import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts'
// import { PieChart, Pie, Sector, Cell } from 'recharts'
// import { AreaChart, Area } from 'recharts'
// import { BarChart, Bar } from 'recharts'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Card from '@material-ui/core/Card'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Chart from "react-apexcharts";
import { Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';

import dataProvider from '../dataProvider'
import authProvider from '../authProvider'

const { fetchJson } = fetchUtils


const API_URL = process.env.REACT_APP_API_HOSTNAME

class Graph extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        authProvider.checkAuth().then(() => {
            this.call()
        }).catch((err) => {
            console.error(err)
            authProvider.logout().then(() => window.location = '/#/login')
        })
    }

    call() {
        dataProvider.customs.stripe_dashboard().then((res) => {
            dataProvider.customs.getAlert("stripe").then((res2) => {
                dataProvider.customs.getAlert("rapidapi").then((res3) => {
                    this.setState({
                        stripe: res.ret.data,
                        rapidapi: res.rapidApiSubscriptions.data.sort((a, b) => b.__Created_at.localeCompare(a.__Created_at)),
                        alert_stripe: res2.enabled,
                        alert_rapidapi: res3.enabled
                    })
                })
            })

        })
    }

    alert(type) {
        this.setState({ alert: !this.state.alert }, () => {
            dataProvider.customs.alert(type, this.state.alert).then(() => {
                this.call()
            })
        })
    }

    renderAlert() {
        if (!this.state.stripe)
            return <></>
        return (
            <div style={{ marginBottom: "10px", display: "flex" }}>
                <div style={{ marginRight: "10px" }}>
                    <Card style={{ padding: "10px", display: "flex" }} >
                        <h5>{"Alert Stripe: "}</h5>
                        <div style={{ paddingTop: "10px", marginLeft: "20px" }}>
                            <Switch label={"Alert"} checked={this.state.alert_stripe} onChange={() => this.alert("stripe")} />
                        </div>
                    </Card>
                </div>
                <div style={{ marginRight: "10px" }}>
                    <Card style={{ padding: "10px", display: "flex" }} >
                        <h5>{"Alert RapidAPI: "}</h5>
                        <div style={{ paddingTop: "10px", marginLeft: "20px" }}>
                            <Switch label={"Alert"} checked={this.state.alert_rapidapi} onChange={() => this.alert("rapidapi")} />
                        </div>
                    </Card>
                </div>
            </div>
        )
    }

    renderDashboard() {
        if (!this.state.stripe)
            return <></>
        return (
            <div>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Start</TableCell>
                                <TableCell>End</TableCell>
                                <TableCell>Payement</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.stripe.map(e => (
                                    <TableRow>
                                        <TableCell>{e.items.data[0].price.price.product.name}</TableCell>
                                        <TableCell>{e.customer.email}</TableCell>
                                        <TableCell><div style={{ color: e.status === "active" ? "green" : "red" }}>{e.status}</div></TableCell>
                                        <TableCell>{e.plan.amount / 100}</TableCell>
                                        <TableCell>{new Date(e.current_period_start * 1000).toISOString()}</TableCell>
                                        <TableCell>{new Date(e.current_period_end * 1000).toISOString()}</TableCell>
                                        <TableCell>{new Date(e.current_period_start * 1000 + 5 * 24 * 3600 * 1000).toISOString()}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
                <div style={{ height: "40px" }}></div>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Start</TableCell>
                                <TableCell>End</TableCell>
                                <TableCell>Payement</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.stripe.reduce((acc, v) => [...acc, ...v.invoices], []).map(e => (
                                    <TableRow>
                                        <TableCell>{e.customer_email}</TableCell>
                                        <TableCell><div style={{ color: e.status === "paid" ? "green" : "red" }}>{e.status}</div></TableCell>
                                        <TableCell>{e.total / 100}</TableCell>
                                        <TableCell>{new Date(e.period_start * 1000).toISOString()}</TableCell>
                                        <TableCell>{new Date(e.period_end * 1000).toISOString()}</TableCell>
                                        <TableCell>{new Date(e.due_date * 1000).toISOString()}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
                <div style={{ height: "40px" }}></div>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>API</TableCell>
                                <TableCell>Subscription Period</TableCell>
                                <TableCell>Created at</TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>Plan Name</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell>Estimated Payout Date</TableCell>
                                <TableCell>Payout Status</TableCell>
                                <TableCell>Overages</TableCell>
                                <TableCell>Total Amount</TableCell>
                                <TableCell>Net Payout</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.rapidapi.map(e => (
                                    <TableRow>
                                        <TableCell>{e.API}</TableCell>
                                        <TableCell>{e.__Subscription_Period}</TableCell>
                                        <TableCell>{e.__Created_at}</TableCell>
                                        <TableCell>{e.__Username}</TableCell>
                                        <TableCell>{e.__Plan_Name}</TableCell>
                                        <TableCell><div style={{ color: e.__Payment_Status === "Paid" ? "green" : "red" }}>{e.__Payment_Status}</div></TableCell>
                                        <TableCell>{e.__Estimated_Payout_Date}</TableCell>
                                        <TableCell style={{ color: e.__Payout_Status === "Paid" ? "green" : "red" }}>{e.__Payout_Status}</TableCell>
                                        <TableCell>{e.__Overages}</TableCell>
                                        <TableCell>{e.__Total_Amount}</TableCell>
                                        <TableCell>{e.__Net_Payout}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }

    render() {
        return (
            <div style={{ marginTop: "20px" }}>
                {this.renderAlert()}
                {this.renderDashboard()}
            </div>
        )
    }
}

export default Graph
