// In theme.js
import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createPalette from "@material-ui/core/styles/createPalette";

const palette = createPalette(
  merge({}, defaultTheme.palette, {
    primary: {
      main: "#ff0266",
    },
    secondary: {
      main: "#80014e",
    },
  })
);

const rawTheme = {
    palette,
};

const theme = createMuiTheme(
  merge({}, defaultTheme, rawTheme)
);

export default theme