import * as React from 'react';
import { FC, memo, useMemo, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import ImageEye from '@material-ui/icons/Cancel';
import ImageEye2 from '@material-ui/icons/TimerOutlined';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import { stringify } from 'query-string'

import { Button } from 'react-admin';
import dataProvider from '../dataProvider';

const API_URL = process.env.REACT_APP_API_HOSTNAME;


const ShowButton = ({
    resource,
    basePath = '',
    icon = defaultIcon,
    label = 'Abort',
    record,
    scrollToTop = true,
    ...rest
}) => {

    const [state, setState] = useState({
        loading: false,
    });

    return (
        <Button
            // component={Link}
            // to={useMemo(
            //     () => ({
            //         pathname: record
            //             ? `${linkToRecord(basePath, record.id)}/show`
            //             : '',
            //         state: { _scrollToTop: scrollToTop },
            //     }),
            //     [basePath, record, scrollToTop]
            // )}
            label={label}
            disabled={record.locked || state.loading}
            onClick={() => {
                setState({ loading: true })
                dataProvider.customs.abortJob(record.id).then(() => window.location.reload())
            }}
            {...(rest)}
        >
            <ImageEye />
        </Button>
    )
};

const defaultIcon = <ImageEye />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

ShowButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
    scrollToTop: PropTypes.bool,
};

const PureShowButton = memo(
    ShowButton,
    (props, nextProps) =>
        (props.record && nextProps.record
            ? props.record.id === nextProps.record.id
            : props.record == nextProps.record) && // eslint-disable-line eqeqeq
        props.basePath === nextProps.basePath &&
        props.to === nextProps.to &&
        props.disabled === nextProps.disabled
);

export default PureShowButton;