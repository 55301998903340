
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    FunctionField,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    ReferenceField,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/AccountBoxOutlined'
import ShortUrlField from '../Customs/ShortUrlField'
import MultipleField from '../Customs/MultipleField'
import MultipleInput from '../Customs/MultipleInput'
import DeleteAllButton from '../Customs/DeleteAllButton'
import ImportButton from '../Customs/ImportButton'
import ApiSelectInput from '../Customs/ApiSelectInput'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const InpiIcon = BookIcon

const Inpi_Filter = (props) => (
    <Filter {...props}>
        <TextInput source="siren" />
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

export const InpiList = (props) => (
    <List {...props} filters={<Inpi_Filter />} actions={<ListActions />} perPage={10}>
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.siren : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="id" sortable={true} />
                    <TextField source="siren" sortable={false} />
                    <TextField source="dirigeant" sortable={false} />
                    <TextField source="capital_social" sortable={false} />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
)

export const InpiShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="siren" />
            <TextField source="dirigeant" />
            <TextField source="capital_social" />

            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />

        </SimpleShowLayout>
    </Show>
)

export const InpiEdit = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="dirigeant" />
            <TextInput source="capital_social" />
        </SimpleForm>
    </Create>
)

export const InpiCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="siren" validate={required()} />
            <TextInput source="dirigeant" />
            <TextInput source="capital_social" />
        </SimpleForm>
    </Create>
)
