import * as React from 'react';
import { FC, memo, ElementType } from 'react';
import get from 'lodash/get';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

import { sanitizeFieldRestProps } from 'react-admin';

import ReactJson from 'react-json-view'


const TextField = memo(
    ({ className, source, record = {}, emptyText, ...rest }) => {
        const value = get(record, source);

        return (
            value
                ?
                <ReactJson src={value} {...(rest.reactJsonOptions || {})} />
                :
                <div></div>
        );
    }
);

// what? TypeScript looses the displayName if we don't set it explicitly
TextField.displayName = 'TextField';

TextField.defaultProps = {
    addLabel: true,
};

export default TextField;