
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    RichTextField,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    SelectArrayInput,
    ReferenceField,
    ImageField,
    BooleanField,
    BooleanInput,
    SearchInput,
    DateTimeInput,
    FunctionField,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import Icon from '@material-ui/icons/Book';

import JsonField from '../Customs/JsonField';
import ReactJson from 'react-json-view'

export const LinkedinLinkIcon = Icon

const filterArray = [
    <TextInput source="siren" />,
]

const FilterList = (props) => (
    <Filter {...props}>
        {filterArray}
    </Filter>
)

const ListActions = (props) => {
    console.log(props)
    return (
        <TopToolbar>
            <FilterButton filters={filterArray} />
        </TopToolbar>
    );
}


export const LinkedinLinkList = (props) => (
    <List {...props} filters={<FilterList />} actions={<ListActions />} perPage={10} pagination={<Pagination rowsPerPageOptions={[5, 10, 20, 50]} />}>
        <Datagrid>
            <TextField source="id" sortable={true} />
            <TextField source="siren" sortable={false} />
            <TextField source="link" sortable={false} />
            <TextField source="type" sortable={false} />
            <TextField source="role" sortable={false} />
            <DateField source="updatedAt" showTime={true} sortable={true} />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List >
)

export const LinkedinLinkShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="siren" />
            <TextField source="link" />
            <TextField source="type" />
            <TextField source="role" />
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)
