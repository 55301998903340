
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    JobInput,
    JobField,
    ArrayInput,
    SelectInput,
    NumberInput,
    SimpleFormIterator,
    required,
    sanitizeListRestProps,
    BooleanInput,
    BooleanField,
    SaveButton,
    DeleteButton,
    Toolbar,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/PostAdd'
import ShortUrlField from '../Customs/ShortUrlField'
import AbortJobButton from '../Customs/AbortJobButton'
import DeleteCsvJobButton from '../Customs/DeleteCsvJobButton'
import GoToCsvJobButton from '../Customs/GoToCsvJobButton'
import MultipleField from '../Customs/MultipleField'
import MultipleInput from '../Customs/MultipleInput'
import DeleteAllButton from '../Customs/DeleteAllButton'
import ImportButton from '../Customs/ImportButton'
import MoveFormIterator from '../Customs/MoveFormIterator'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const JobIcon = BookIcon

const Job_Filter = (props) => (
    <Filter {...props}>
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

export const JobList = (props) => (
    <List {...props} filters={<Job_Filter />} actions={<ListActions />} perPage={40}>
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.name : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="id" sortable={false} />
                    <TextField source="code" sortable={false} />
                    <TextField source="status" sortable={false} />
                    <TextField source="per" sortable={false} />
                    <TextField source="duration" sortable={false} />
                    <TextField source="flags" sortable={false} />
                    <ShowButton />
                    <GoToCsvJobButton />
                    <AbortJobButton />
                    <DeleteCsvJobButton />
                </Datagrid>
            }
        />
    </List>
)

export const JobShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="code" />
            <TextField source="status" />
            <TextField source="per" />
            <TextField source="duration" />
            <TextField source="flags" />
        </SimpleShowLayout>
    </Show>
)

export const JobEdit = (props) => (
    <Edit {...props} undoable={false} >
        <SimpleForm redirect="show">
        </SimpleForm>
    </Edit>
)

export const JobCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="code" validate={required()} />
            <TextInput source="flags" />
        </SimpleForm>
    </Create>
)
