import * as React from 'react';
import { FC, ReactElement, memo, useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone'
import PropTypes from 'prop-types';
import { Fab, useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContentAdd from '@material-ui/icons/PostAdd';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FlatButton from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslate } from 'ra-core';

import { Button, showNotification } from 'react-admin';

import dataProvider from '../dataProvider'

const csvtojson = require("csvtojson")

const sanitizeButtonRestProps = ({
    // The next props are injected by Toolbar
    basePath,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    onSave,
    pristine,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    undoable,
    ...rest
}) => rest;

const CreateButton = props => {
    const translate = useTranslate();
    const {
        basePath = '',
        className,
        classes: classesOverride,
        label = translate("ra.customs.import"),
        icon = defaultIcon,
        variant,
        ...rest
    } = props;
    const classes = useStyles(props);
    const isSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('sm')
    );
    const [state, setState] = useState({
        completed: 0,
        open1: false,
        open2: false,
        determinate: true,
        files: []
    });
    let buffer = []

    const handleSubmit = () => {
        const reader = new FileReader()
        reader.onload = async (e) => {
            const csvtojsonPromise = (str) => new Promise(resolve => csvtojson().fromString(str).then(json => resolve(json)))
            const json = rest.parser ? rest.parser(e.target.result) : await csvtojsonPromise(e.target.result)
            const arrFields = []
            for (let i in json)
                for (let key in json[i])
                    if (!arrFields.includes(key) && json[i][key].indexOf('|') !== -1)
                        arrFields.push(key)
            for (let i in json)
                for (let j in arrFields)
                    json[i][arrFields[j]] = json[i][arrFields[j]].split('|')
            buffer = json
            setState({ open1: false, open2: true, determinate: true, completed: 0, files: [] })
            upload()
        };
        reader.readAsText(state.files[0])
    }

    const upload = (index = 0, forceMethod) => {
        const { resource } = props;
        const elem = buffer[index]
        console.log({ elem })
        delete elem.createdAt
        delete elem.updatedAt
        const method = forceMethod ? forceMethod : (elem.id ? 'update' : 'create')
        if (method === 'create')
            delete elem.id
        dataProvider[method](resource, { data: elem, id: elem.id })
            .then((response) => {
                this.setState({
                    open1: false, open2: true, determinate: true, files: [],
                    completed: 100 * index / this.buffer.length
                })
                if (index < buffer.length && (state.open1 || state.open2)) {
                    upload(index + 1)
                } else {
                    setState({ open1: false, open2: false, determinate: false, completed: 0, files: [] });
                    buffer = []
                }
            })
            .catch((e) => {
                if (method === 'create') {
                    setState({
                        open1: false, open2: true, determinate: true, files: [],
                        completed: 100 * index / buffer.length
                    })
                    if (index + 1 < buffer.length && (state.open1 || state.open2)) {
                        upload(index + 1)
                    } else {
                        setState({ open1: false, open2: false, determinate: false, completed: 0, files: [] });
                        buffer = []
                    }
                } else {
                    upload(index, 'create')
                }
            });
    }

    return <div>
        {
            isSmall ? (
                <Fab
                    onClick={() => setState({ open1: true, open2: false, determinate: true, completed: 0, files: [] })}
                    className={classnames(
                        'ra-create-button',
                        classes.floating,
                        className
                    )}
                    aria-label={label && translate(label)}
                    {...sanitizeButtonRestProps(rest)}
                >
                    {icon}
                </Fab>
            ) : (
                <Button
                    onClick={() => setState({ open1: true, open2: false, determinate: true, completed: 0, files: [] })}
                    className={classnames(
                        'ra-create-button',
                        classes.nofloating,
                        className
                    )}
                    label={label}
                    variant={variant}
                    {...(rest)}
                >
                    {icon}
                </Button>
            )
        }
        <Dialog
            open={state.open1}
        >
            <DialogTitle id="form-dialog-title">{translate("ra.customs.choose_a_file")}</DialogTitle>
            <DialogContent>
                <DropzoneArea
                    accept={".csv"}
                    multiple={false}
                    onChange={(newFiles) => {
                        setState({ open1: true, open2: false, determinate: true, completed: 0, files: newFiles })
                    }}
                />
            </DialogContent>
            <DialogActions>
                <FlatButton disabled={state.files.length === 0} onClick={() => {
                    setTimeout(() => handleSubmit(), 1)
                }} color="primary">
                    {translate("ra.customs.submit")}
                </FlatButton>
                <FlatButton onClick={() => setState({ open1: false, open2: false, determinate: true, completed: 0, files: [] })} color="primary">
                    {translate("ra.customs.close")}
                </FlatButton>
            </DialogActions>
        </Dialog>
        <Dialog
            open={state.open2}
        >
            <DialogTitle id="form-dialog-title">Importing...</DialogTitle>
            <DialogContent>
                <LinearProgress variant={state.determinate ? "determinate" : "indeterminate"} value={state.completed} />
            </DialogContent>
        </Dialog>
    </div>
};

const defaultIcon = <ContentAdd />;

const useStyles = makeStyles(
    theme => ({
        nofloating: {
            color: theme.palette.primary.main,
        },
        floating: {
            color: theme.palette.primary.main,
            margin: 0,
            top: 'auto',
            right: 180,
            bottom: 60,
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
        },
        floatingLink: {
            color: 'inherit',
        },
    }),
    { name: 'RaDeleteWithUndoButton' }
);

CreateButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
};

export default memo(CreateButton, (prevProps, nextProps) => {
    return (
        prevProps.basePath === nextProps.basePath &&
        prevProps.label === nextProps.label &&
        prevProps.translate === nextProps.translate &&
        prevProps.to === nextProps.to
    );
});

